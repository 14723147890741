import { cloneDeep, isArray, truncate } from 'lodash';
import { Utils as QbUtils } from 'react-awesome-query-builder';

// Components
import ScenarioBlacklistSelector from 'app/modules/detectionModels/components/scenarioWidgets/ScenarioBlacklistSelector';
import ScenarioPerBlacklistEditor from 'app/modules/detectionModels/components/scenarioWidgets/ScenarioPerBlacklistEditor';
import ScenarioSequenceCodeEditor from 'app/modules/detectionModels/components/scenarioWidgets/ScenarioSequenceCodeEditor';
import WonoloScenarioPerBlacklistEditor from 'app/modules/detectionModels/components/scenarioWidgets/WonoloScenarioPerBlacklistEditor';
import { EntityLinkCountMockGraph } from 'app/modules/detectionModels/components/scenarioWidgets/EntityLinkCountMockGraph';

// Selectors
import { ComponentRulesSelector } from 'app/modules/detectionModels/components/scenarioWidgets/ComponentRulesSelector';

// Models
import {
  DetectionModel,
  DetectionModelMetadata,
  DetectionModelTemplate,
  DetectionModelValidation,
  EditingAdvancedDetectionModel,
  EditingDetectionModel,
  EditingSimpleDetectionModel,
  ModelValidationPayload,
  QueryTreeFiltersModel,
  ScenarioConfigs,
  ScenarioPayload,
  SimpleModelStep,
  ValidateModelPayload,
} from 'app/modules/detectionModels/models';
import {
  RuleScenarioFilters,
  ScenarioConstants,
} from 'app/modules/rules/models';
import { OrgScenarioConfig } from 'app/modules/orgSettings/models';
import {
  CompositeOperator,
  CompositeQueryTreeNode,
  ConstantValue,
  DmAggregateFunction,
  DmEventByObjectFact,
  DmEventSubtype,
  DmObjectToFlag,
  DmOperator,
  FieldValue,
  IRSchema,
  QueryTreeNode,
  VariableValue,
} from 'app/modules/rulesAdvanced/types/RulesRepresentation';
import {
  ScenarioBuilderWidgets,
  WidgetProps,
} from 'app/modules/detectionModels/components/scenarioWidgets/models';
import {
  ConditionOperand,
  FieldValueOperand,
  OperandSymbol,
  OperandType,
  SymbolFieldValue,
  SymbolTable,
} from 'app/modules/rulesAdvanced/lib/SymbolTable';
import { U21Button, U21SelectOptionProps } from 'app/shared/u21-ui/components';
import { BlacklistType } from 'app/modules/lists/models';
import {
  CustomDataSettingsConfigResponse,
  DataSettingFieldType,
  NativeDataSettingsConfigResponse,
  OrgDataSettingsConfig,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import { DmbQueryBuilderOption } from 'app/modules/rulesAdvanced/utils/SymbolTableBuilder';
import {
  AndOrCondition,
  Condition,
  ConditionOperandValue,
} from 'app/modules/detectionModels/components/DropdownBuilderForm/models';

// Constants
import {
  ACH_RISK_SCORE_ENABLED_SCENARIO_RULES,
  CHECK_FRAUD_RULES_ALL,
  ConditionalOperators,
  CONSORTIUM_RULES,
  EMPTY_FIELD_VALUE,
  FLAGGED_OBJECT_PARAMETER,
  MATCHLIST_OPERATOR_OPTIONS,
  MAX_DROPDOWN_OPTION_DESCRIPTION_LENGTH,
  OPERAND_TYPE_OPERATOR_OPTIONS_MAP,
  SCENARIO_ACH_CONSORTIUM_NAME,
  SCENARIO_ACH_DUPLICATE_NAME,
  SCENARIO_ACH_MISMATCHED_NAME,
  SCENARIO_ACH_RISK_SCORE_NAME,
  SCENARIO_BUILDER_CHECK_FRAUD_RULES,
  SCENARIO_DUPLICATE_SERIAL_NUMBER_NAME,
  SCENARIO_MISSING_SERIAL_NUMBER_NAME,
  SCENARIO_MULTIPLE_FAILED_VALIDATION_ATTEMPTS_NAME,
  LEGACY_SEQUENCE_RULES,
  SIMPLE_MODEL_STEPS,
  WATCHLIST_RULE_NAME,
  SCENARIO_SEQUENCE_CUMULATIVE_AGGREGATE_NAME,
  SCENARIO_SEQUENCE_ROLLING_WINDOW_NAME,
  SCENARIO_SEQUENCE_DAILY_AGGREGATE_NAME,
  SCENARIO_SEQUENCE_ROLLING_WINDOW_ONE_STEP_NAME,
  SCENARIO_SEQUENCE_CUMULATIVE_AGGREGATE_USPS_NAME,
} from 'app/modules/detectionModels/constants';
import {
  BE_DATE_FORMAT,
  INPUT_FIELD_DATETIME_VALUE_FORMAT,
} from 'app/shared/constants';
import { DECISION_MAP, TIME_STRING_FORMAT } from 'app/modules/rules/constants';
import { TIME_WINDOW_OPTIONS } from 'app/modules/rules/config/timeWindow';
import { NATIVE_DATA_SETTINGS_NATIVE_KEY_MISMATCH_MAP } from 'app/modules/dataSettings/constants';
import { TXN_EVENT_COMMON_DROPDOWN_SYMBOLS } from 'app/modules/detectionModels/txnEventCommonDropdownSymbols';
import { TXN_EVENT_SENDER_RECEIVER_ENTITY_DROPDOWN_SYMBOLS } from 'app/modules/detectionModels/txnEventSenderReceiverEntityDropdownSymbols';
import { ACTION_EVENT_ADVANCED_SYMBOLS } from 'app/modules/detectionModels/actionEventAdvancedSymbols';
import { ACTION_EVENT_DROPDOWN_SYMBOLS } from 'app/modules/detectionModels/actionEventDropdownSymbols';
import { TXN_EVENT_COMMON_ADVANCED_SYMBOLS } from 'app/modules/detectionModels/txnEventCommonAdvancedSymbols';
import { TXN_EVENT_SENDER_RECEIVER_ADVANCED_SYMBOLS } from 'app/modules/detectionModels/txnEventSenderReceiverEntityAdvancedSymbols';

// Helpers
import { consoleError } from 'app/shared/utils/console';
import { getBEDateFormat, getLocalFormat } from 'app/shared/utils/timeHelpers';
import {
  DURATION_UNIT_MAP,
  getWindowSizeFromIR,
  MONTH_UNIT_MAP,
} from 'app/modules/rulesAdvanced/lib/IRHelpers';
import { format, subMinutes } from 'date-fns';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';
import { toIrSchemaOrNull } from 'app/modules/rulesAdvanced/helper';
import { SequenceRuleSpec } from 'app/modules/detectionModels/types/SequenceRuleContentSchema';
import { toSentenceCase } from 'app/shared/utils/string';

type DmbQueryBuilderOptionCode =
  | 'entity'
  | 'txn_instrument'
  | 'device'
  | 'txn_event'
  | 'action_event'
  | 'address';

type DmbQueryBuilderOptionCodeFriendlyName =
  | 'Entity'
  | 'Instrument'
  | 'Device'
  | 'Transaction event'
  | 'Action event'
  | 'Address';

// NOTE: used where ever model needs to be lowercased
const DM_OBJECT_ENUM_TO_CODE_MAP: {
  [key in Extract<
    DmbQueryBuilderOption,
    'ACTION_EVENT' | 'TXN_EVENT'
  >]: DmbQueryBuilderOptionCode;
} = {
  TXN_EVENT: 'txn_event',
  ACTION_EVENT: 'action_event',
};

export const U21_LATEST_RISK_SCORE_KEY = 'u21_latest_risk_score';
export const U21_RISK_LEVEL_KEY = 'u21_latest_risk_level';
const U21_RISK_LEVELS = ['Low', 'Medium', 'High'];

export const getValidationPayload = (
  metadata: DetectionModelMetadata | null,
  validationPayload: ModelValidationPayload | null,
  dataMigrationLag: number,
): ModelValidationPayload => {
  const isNewRule =
    metadata === null ||
    (metadata?.title === '' && metadata?.description === '');
  const isActiveRule =
    validationPayload !== null &&
    validationPayload.start_time !== '' &&
    validationPayload.end_time === '';

  const EXTRA_BUFFER_FOR_SF_DATA_MIGRATION_LAG = 60;

  let subAmount = 0;
  if (validationPayload === null) {
    subAmount = 43800;
  } else if (isNewRule) {
    subAmount = dataMigrationLag * 2;
  }

  return {
    execution_frequency:
      validationPayload === null ? '' : validationPayload.execution_frequency,
    start_time: format(
      subMinutes(
        validationPayload === null || validationPayload.start_time === ''
          ? new Date()
          : new Date(validationPayload.start_time),
        subAmount,
      ),
      TIME_STRING_FORMAT,
    ),
    end_time: format(
      subMinutes(
        validationPayload === null || validationPayload.end_time === ''
          ? new Date()
          : new Date(validationPayload.end_time),
        isNewRule || isActiveRule
          ? dataMigrationLag + EXTRA_BUFFER_FOR_SF_DATA_MIGRATION_LAG
          : 0,
      ),
      TIME_STRING_FORMAT,
    ),
  };
};

// =========== Shared DM Helpers =============
export const editingValidatedModel = (
  detectionModelValidation: EditingDetectionModel,
): boolean => {
  return Boolean(detectionModelValidation.ruleId);
};

export const getModelMetadataFromValidation = ({
  title,
  description,
  tags,
  customer_support_involved: customerSupportInvolved,
  queue,
  runs_on_org_id: runsOnOrgID,
  visible_to_child_org: visibleToChildOrg,
  decision,
}: {
  title: string;
  description: string;
  tags: number[];
  queue: number | null;
  runs_on_org_id: number | null;
  customer_support_involved: boolean | null;
  visible_to_child_org: boolean | null;
  decision?: string;
}): DetectionModelMetadata => {
  return {
    customer_support_involved: Boolean(customerSupportInvolved),
    title,
    description,
    tags: tags || [],
    queue,
    runs_on_org_id: runsOnOrgID,
    visible_to_child_org: Boolean(visibleToChildOrg),
    decision,
  };
};

export const getValidationPayloadFromValidation = (
  detectionModelValidation: Pick<
    DetectionModelValidation,
    'exec_range_end' | 'exec_range_start' | 'execution_frequency' | 'content'
  >,
  dateFormat = INPUT_FIELD_DATETIME_VALUE_FORMAT,
): ModelValidationPayload => {
  const startTime = getLocalFormat(
    detectionModelValidation.exec_range_start,
    dateFormat,
    BE_DATE_FORMAT,
  );
  const endTime = getLocalFormat(
    detectionModelValidation.exec_range_end,
    dateFormat,
    BE_DATE_FORMAT,
  );

  const executionFrequency = detectionModelValidation.content.scenario
    .parameters.$window_stride
    ? detectionModelValidation.content.scenario.parameters.$window_stride
    : '';
  return {
    start_time: startTime,
    end_time: endTime,
    execution_frequency:
      detectionModelValidation.execution_frequency || executionFrequency,
  };
};

// =========== Simple DM Helpers =============
export const getEditingSimpleDetectionModelFromValidation = (
  detectionModelValidation: Pick<
    DetectionModelValidation,
    | 'content'
    | 'id'
    | 'title'
    | 'description'
    | 'tags'
    | 'exec_range_end'
    | 'exec_range_start'
    | 'execution_frequency'
    | 'customer_support_involved'
    | 'queue'
    | 'runs_on_org_id'
    | 'visible_to_child_org'
    | 'decision'
  >,
): EditingSimpleDetectionModel => {
  const { scenario, specification } = detectionModelValidation.content;
  const embeddedFiltersAsQueryTree = scenarioFiltersToEmbeddedFilters(
    scenario.filters,
  );

  return {
    metadata: getModelMetadataFromValidation(detectionModelValidation),
    validationPayload: getValidationPayloadFromValidation(
      detectionModelValidation,
    ),
    objectToFlag: scenario.parameters[FLAGGED_OBJECT_PARAMETER] || null,
    scenario: {
      name: scenario.name,
      embeddedFilters: embeddedFiltersAsQueryTree,
      parameters: scenario.parameters,
    },
    ruleId: detectionModelValidation.id,
    specification,
  };
};

export const getDMValidationPayload = (
  editingSimpleDetectionModel: EditingSimpleDetectionModel,
  dateFormat = INPUT_FIELD_DATETIME_VALUE_FORMAT,
): ValidateModelPayload => {
  const startTime = getBEDateFormat(
    editingSimpleDetectionModel.validationPayload?.start_time || '',
    dateFormat,
  );
  const endTime = getBEDateFormat(
    editingSimpleDetectionModel.validationPayload?.end_time || '',
    dateFormat,
  );
  const embeddedFiltersQueryTreeAsJson = embeddedFiltersToJson(
    editingSimpleDetectionModel.scenario?.embeddedFilters || {},
  );

  return {
    // Scenario content
    contents: {
      scenario: {
        scenario_name: editingSimpleDetectionModel.scenario?.name || '',
        parameters: {
          ...editingSimpleDetectionModel.scenario?.parameters,

          $time_window:
            editingSimpleDetectionModel.scenario?.parameters.$time_window || '',
          $window_stride:
            editingSimpleDetectionModel.validationPayload
              ?.execution_frequency || '',
        },
        filters: embeddedFiltersQueryTreeAsJson,
      },
      specification: editingSimpleDetectionModel.specification,
      execution_frequency:
        editingSimpleDetectionModel.validationPayload?.execution_frequency ||
        '',

      // TODO remove this, it was intended for old filters
      query_tree: {},
      inclusion_tags: [],
      inclusion_tag_names: [],
      exclusion_tags: [],
      exclusion_tag_names: [],
    },

    // Validation time range
    exec_range_start: startTime,
    exec_range_end: endTime,

    // Add metadata
    tags: editingSimpleDetectionModel.metadata?.tags || [],
    metadata: {
      title: editingSimpleDetectionModel.metadata?.title || '',
      description: editingSimpleDetectionModel.metadata?.description || '',
      customer_support_involved:
        editingSimpleDetectionModel.metadata?.customer_support_involved ||
        false,
      parentRuleId: editingSimpleDetectionModel.ruleId || undefined,
      exec_start_date_time: startTime,
      exec_end_date_time: endTime,
    },
    runs_on_org_id: editingSimpleDetectionModel.metadata?.runs_on_org_id,
    visible_to_child_org: Boolean(
      editingSimpleDetectionModel.metadata?.visible_to_child_org,
    ),
  };
};

export const getAvailableSteps = (
  editingModel: EditingSimpleDetectionModel,
  scenarioConfigs: ScenarioConfigs,
  orgScenarioConfig: OrgScenarioConfig,
  modelSteps = SIMPLE_MODEL_STEPS,
): Set<string> => {
  // Goes over the linked list until it can no longer continue, returns a set of visited steps
  const availableSteps = new Set<string>();

  const allSteps = Object.keys(modelSteps) as SimpleModelStep[];
  const nextSteps = Object.values(modelSteps).map(
    (stepConfig) => stepConfig.nextStep,
  );
  const initialStep = allSteps.filter((step) => !nextSteps.includes(step))[0];

  // Try to advance as many steps as possible
  if (initialStep) {
    let currentStep = initialStep;
    let canContinue = modelSteps[currentStep].canContinue(
      editingModel,
      scenarioConfigs,
      orgScenarioConfig,
    );
    let { nextStep } = modelSteps[currentStep];
    availableSteps.add(currentStep);
    while (canContinue && nextStep !== null) {
      currentStep = nextStep;
      canContinue = modelSteps[currentStep].canContinue(
        editingModel,
        scenarioConfigs,
        orgScenarioConfig,
      );
      nextStep = modelSteps[currentStep].nextStep;
      availableSteps.add(currentStep);
    }
  }

  return availableSteps;
};

export const getInitialStep = (
  editingModel: EditingSimpleDetectionModel,
  scenarioConfigs: ScenarioConfigs,
  orgScenarioConfig: OrgScenarioConfig,
  modelSteps = SIMPLE_MODEL_STEPS,
): SimpleModelStep => {
  const allSteps = Object.keys(modelSteps) as SimpleModelStep[];
  const nextSteps = Object.values(modelSteps).map(
    (stepConfig) => stepConfig.nextStep,
  );
  const initialStep = allSteps.filter((step) => !nextSteps.includes(step))[0];

  // Try to advance as many steps as possible
  if (initialStep) {
    let currentStep = initialStep;
    let canContinue = modelSteps[currentStep].canContinue(
      editingModel,
      scenarioConfigs,
      orgScenarioConfig,
    );
    let { nextStep } = modelSteps[currentStep];
    while (canContinue && nextStep !== null) {
      currentStep = nextStep;
      canContinue = modelSteps[currentStep].canContinue(
        editingModel,
        scenarioConfigs,
        orgScenarioConfig,
      );
      nextStep = modelSteps[currentStep].nextStep;

      // We can advance to the passed initialStep, return it
      if (currentStep === editingModel.initialStep) {
        return editingModel.initialStep;
      }
    }
    return currentStep;
  }

  // Default, return initial step
  return 'SCENARIO_BUILDER';
};

export const getPrevStep = (
  currentStep: SimpleModelStep,
  modelSteps = SIMPLE_MODEL_STEPS,
): SimpleModelStep | null => {
  const prevStep = Object.keys(modelSteps).filter(
    (step) => modelSteps[step].nextStep === currentStep,
  ) as SimpleModelStep[];
  if (prevStep.length > 0) {
    return prevStep[0];
  }
  return null;
};

export const getNextStep = (
  currentStep: SimpleModelStep,
  modelSteps = SIMPLE_MODEL_STEPS,
): SimpleModelStep | null => modelSteps[currentStep].nextStep;

export const isAdvancedModel = (
  detectionModel: DetectionModel | DetectionModelValidation,
): boolean => {
  return detectionModel.scenario === 'Dynamic model';
};

export const isRealTimeMonitoringModel = (
  detectionModel: DetectionModel | DetectionModelValidation,
): boolean =>
  detectionModel.content.scenario.parameters?.flag_single_object ?? false;

export const scenarioFiltersToEmbeddedFilters = (
  filters: RuleScenarioFilters = {},
): ScenarioPayload['embeddedFilters'] => {
  return Object.keys(filters).reduce(
    (accumulated: ScenarioPayload['embeddedFilters'], filterKey) => {
      const jsonFilter = filters[filterKey];
      return {
        ...accumulated,
        [filterKey]: {
          ...jsonFilter,
          query_tree: QbUtils.loadTree(jsonFilter.query_tree as any),
        },
      };
    },
    {},
  );
};

export const embeddedFiltersToJson = (
  embeddedFilters: ScenarioPayload['embeddedFilters'],
): RuleScenarioFilters => {
  return Object.keys(embeddedFilters).reduce((filters, filterKey) => {
    const qTreeFilter: QueryTreeFiltersModel = embeddedFilters[filterKey];
    return {
      ...filters,
      [filterKey]: {
        ...qTreeFilter,
        query_tree: qTreeFilter.query_tree.toJS(),
      },
    };
  }, {});
};

export const buildAdvancedRulesPayloadValidationPayload = (
  validationPayload: ModelValidationPayload,
  metaData: DetectionModelMetadata,
  irRule: IRSchema,
  irTemplate: DetectionModelTemplate | null,
  groupByType: DmObjectToFlag | 'HIT' | undefined,
  flagSingleObject?: boolean,
): ValidateModelPayload => {
  const startTime = getBEDateFormat(
    validationPayload.start_time,
    INPUT_FIELD_DATETIME_VALUE_FORMAT,
  );

  const endTime = getBEDateFormat(
    validationPayload.end_time,
    INPUT_FIELD_DATETIME_VALUE_FORMAT,
  );

  const objectGroups: Record<string, string> = {
    ENTITY: 'entity.id',
    INSTRUMENT: 'txn_instrument.id',
    DEVICE: 'device.id',
    HIT: 'hit.id',
  };

  const getGroupType = (groupType: string | undefined): string => {
    if (!groupType) {
      return 'entity.id';
    }
    return objectGroups[groupType];
  };

  return {
    tags: metaData.tags,
    metadata: {
      title: metaData.title,
      description: metaData.description,
      decision: metaData.decision,
      customer_support_involved: false,
      exec_start_date_time: startTime,
      exec_end_date_time: endTime,
    },
    contents: {
      query_tree: {},
      inclusion_tags: [],
      inclusion_tag_names: [],
      exclusion_tags: [],
      exclusion_tag_names: [],
      scenario: {
        parameters: {
          $window_stride: validationPayload.execution_frequency,
          $time_window: getWindowSizeFromIR(irRule),
          alert_group_by_policy: groupByType,
          count_field: 'txn_event.id',
          group_type: getGroupType(groupByType),
          txn_events: 'transaction',
          flag_single_object: flagSingleObject,
        },
        filters: {},
        scenario_name: 'custom_scenario',
      },
      execution_frequency: validationPayload.execution_frequency,
      specification: irRule,
    },
    exec_range_start: startTime,
    exec_range_end: endTime,
    template_id: irTemplate?.id || undefined,
    runs_on_org_id: metaData.runs_on_org_id,
    visible_to_child_org: Boolean(metaData.visible_to_child_org),
    is_synchronous: flagSingleObject || false
  };
};

export const getEditingAdvancedDetectionModelFromValidation = (
  detectionModelValidation: Pick<
    DetectionModelValidation,
    | 'content'
    | 'id'
    | 'title'
    | 'description'
    | 'tags'
    | 'exec_range_end'
    | 'exec_range_start'
    | 'execution_frequency'
    | 'customer_support_involved'
    | 'template'
    | 'queue'
    | 'runs_on_org_id'
    | 'visible_to_child_org'
    | 'decision'
  >,
): EditingAdvancedDetectionModel => {
  return cloneDeep({
    metadata: getModelMetadataFromValidation(detectionModelValidation),
    validationPayload: getValidationPayloadFromValidation(
      detectionModelValidation,
    ),
    ruleId: detectionModelValidation.id,
    irSchema: toIrSchemaOrNull(detectionModelValidation.content.specification),
    template: detectionModelValidation.template,
  });
};

export const updateFact = (
  oldFact: DmEventByObjectFact,
  selectedAggregateFunction: DmAggregateFunction,
) => {
  const { aggregate_field: aggregateField } = oldFact;
  const updatedFact = {
    ...oldFact,
    aggregate_function: selectedAggregateFunction,
  };

  if (updatedFact.event_subtype === 'TXN_EVENT') {
    if (typeof aggregateField !== 'string') {
      updatedFact.aggregate_field =
        selectedAggregateFunction === 'COUNT'
          ? {
              ...aggregateField,
              field: 'id',
              datatype: 'text',
            }
          : {
              ...aggregateField,
              field: 'amount',
              datatype: 'number',
            };
    }
  } else if (updatedFact.event_subtype === 'ACTION_EVENT') {
    if (typeof aggregateField !== 'string') {
      if (selectedAggregateFunction === 'COUNT') {
        updatedFact.aggregate_field = {
          ...aggregateField,
          field: 'id',
          datatype: 'text',
        };
      } else if (selectedAggregateFunction === 'SUM') {
        updatedFact.aggregate_field = {
          ...aggregateField,
          field: 'amount',
          datatype: 'number',
        };
      }
    }
  }
  return updatedFact;
};

export const getMatchlistTypes = (
  scenarioName: string,
): Array<BlacklistType> => {
  switch (scenarioName) {
    case 'ip_blacklist':
    case 'ip_blacklist_embedded_filters':
      return ['IP_INET'];
    case 'blacklist_string':
    case 'entity_blacklist_string_embedded_filters':
    case 'event_blacklist_string_embedded_filters':
      return ['STRING'];
    case 'entity_blacklist':
    case 'entity_blacklist_embedded_filters':
      return ['USER', 'BUSINESS'];
    default:
      consoleError('Unknown scenario name: ', scenarioName);
      return [];
  }
};

export const renderScenarioWidgets = (
  scenarioConfig,
  selectedScenario,
  onChange,
  viewOnly = false,
) => {
  if (scenarioConfig.widgets) {
    const widgetProps: WidgetProps = {
      editingScenario: selectedScenario,
      viewOnly,
      onChange,
    };

    return scenarioConfig.widgets.map((widgetName) => {
      switch (widgetName as ScenarioBuilderWidgets) {
        case ScenarioConstants.BLACKLIST_SELECTOR:
          return (
            <ScenarioBlacklistSelector key={widgetName} {...widgetProps} />
          );
        case ScenarioConstants.PER_FIELD_BLACKLIST_EDITOR:
          return (
            <ScenarioPerBlacklistEditor key={widgetName} {...widgetProps} />
          );
        case ScenarioConstants.SEQUENCE_CODE_EDITOR:
          return (
            <ScenarioSequenceCodeEditor key={widgetName} {...widgetProps} />
          );
        case ScenarioConstants.COMPONENT_RULES_SELECTOR:
          return <ComponentRulesSelector key={widgetName} {...widgetProps} />;
        case ScenarioConstants.PER_FIELD_BLACKLIST_EDITOR_WONOLO:
          return (
            <WonoloScenarioPerBlacklistEditor
              key={widgetName}
              {...widgetProps}
            />
          );
        case ScenarioConstants.LINK_ANALYSIS_MOCK:
          return <EntityLinkCountMockGraph key={widgetName} {...widgetProps} />;
        default:
          return <div key={widgetName}>Unknown Widget {widgetName}</div>;
      }
    });
  }
  return null;
};

export const getDmObjectFriendlyName = (
  dmObject: DmObjectToFlag | undefined,
): string => {
  if (!dmObject) {
    return '';
  }
  return dmObject.toLowerCase();
};

/**
 * Returns a prettified version of the time we save, 17w -> 17 weeks
 * @param time - string value representing time, like `17d` `1w` `1m`
 */
export const prettifyTime = (time): string => {
  if (!time) {
    return '';
  }

  // rare cases of 'fdom', 'ldom', 'fmom', etc
  const monthUnit = MONTH_UNIT_MAP[time];

  if (monthUnit) {
    return `${monthUnit}`;
  }

  // must be of a '18w' format
  try {
    const [, timeRange, timeUnit] = time.split(/(\d+)/);
    const formattedUnit = DURATION_UNIT_MAP[timeUnit].toLowerCase();

    if (Number(timeRange) === 1) {
      return `a(n) ${formattedUnit}`;
    }

    return `${timeRange} ${formattedUnit}s`;
  } catch {
    return '';
  }
};

export const generateConstantValue = (
  value: string | number | boolean | (string | number)[] | Date,
): ConstantValue => {
  return {
    type: 'CONSTANT',
    value,
  };
};

export const generateVariableValue = (name: string): VariableValue => {
  return {
    type: 'VARIABLE',
    name,
  };
};

export const PRETTY_OBJECT_MAP: Record<DmObjectToFlag, string> = {
  ENTITY: 'Entity',
  INSTRUMENT: 'Instrument',
  DEVICE: 'Device',
  TXN_EVENT: 'Transaction Event',
  ACTION_EVENT: 'Action Event',
};

export const generateEventByObjectFact = (
  id: string,
  aggregateFunction: DmAggregateFunction,
  eventSubtype: DmEventSubtype,
): DmEventByObjectFact => {
  return {
    type: 'EVENT_BY_OBJECT_FACT',
    id,
    object: 'ENTITY',
    sender_receiver: 'SENDER',
    event_filters: {
      type: 'COMPOSITE',
      operands: [],
      operator: 'AND',
    },
    aggregate_function: aggregateFunction,
    aggregate_field: EMPTY_FIELD_VALUE,
    event_subtype: eventSubtype,
    window_end_offset: {
      type: 'DURATION',
      unit: 'HOUR',
      value: 0,
    },
    window_start_offset: {
      type: 'DURATION',
      unit: 'DAY',
      value: 0,
    },
  };
};

export const filterOperatorOptions = (
  condition: CompositeQueryTreeNode,
  symbolTable: SymbolTable,
): U21SelectOptionProps[] => {
  const leftOperand = getOperandFromSymbolTable(
    condition.operands[0],
    symbolTable,
  );
  const rightOperand = condition.operands[1];

  if (leftOperand && leftOperand.value.type === 'FIELD') {
    if (EMPTY_OPERATORS().has(condition.operator)) {
      return OPERAND_TYPE_OPERATOR_OPTIONS_MAP.empty;
    }

    const { datatype: operandDataType } = leftOperand.value;
    let operatorMapKey: string = operandDataType;
    if (rightOperand?.type === 'MATCHLIST_FIELD') {
      return MATCHLIST_OPERATOR_OPTIONS;
    } else if (
      operandDataType === 'select' ||
      operandDataType === 'multiselect'
    ) {
      operatorMapKey = 'list';
    } else if (operandDataType === 'date' || operandDataType === 'datetime') {
      if (rightOperand && rightOperand.type === 'DURATION')
        return OPERAND_TYPE_OPERATOR_OPTIONS_MAP.number.filter(
          (option) =>
            option.value !== ConditionalOperators.BETWEEN &&
            option.value !== ConditionalOperators.IS,
        );
      return OPERAND_TYPE_OPERATOR_OPTIONS_MAP[operatorMapKey];
    } else if (
      !Object.keys(OPERAND_TYPE_OPERATOR_OPTIONS_MAP).includes(operandDataType)
    ) {
      operatorMapKey = 'text';
    }

    return OPERAND_TYPE_OPERATOR_OPTIONS_MAP[operatorMapKey].filter(
      (option) => {
        switch (option.value) {
          case ConditionalOperators.EQ:
          case ConditionalOperators.NEQ:
          case ConditionalOperators.LIKE:
          case ConditionalOperators.NOT_LIKE:
          case ConditionalOperators.IN:
          case ConditionalOperators.NOT_IN:
            return (
              operandDataType !== 'multiselect' || operatorMapKey === 'list'
            );
          case ConditionalOperators.ANY_EQ:
          case ConditionalOperators.NONE_EQ:
          case ConditionalOperators.ANY_IN:
          case ConditionalOperators.NONE_IN:
          case ConditionalOperators.ANY_LIKE:
          case ConditionalOperators.NONE_LIKE:
            return operandDataType === 'multiselect';
          case ConditionalOperators.GT:
          case ConditionalOperators.GE:
          case ConditionalOperators.LT:
          case ConditionalOperators.LE:
          case ConditionalOperators.BETWEEN:
            return operandDataType === 'number';
          default:
            return false;
        }
      },
    );
  }
  return [];
};

export const isIRSchema = (
  specification: IRSchema | SequenceRuleSpec,
): specification is IRSchema => {
  return Object.hasOwn(specification, 'rule_condition');
};

export const isConditionalOperator = (
  operator?: DmOperator,
): operator is ConditionalOperators => {
  return operator !== undefined && Boolean(ConditionalOperators[operator]);
};

export const isCompositeOperator = (
  operator: DmOperator,
): operator is CompositeOperator => {
  return ['AND', 'OR'].includes(operator);
};

export const isConditionOperand = (
  operand:
    | AndOrCondition
    | Condition
    | ConditionOperand
    | ConditionOperandValue
    | QueryTreeNode,
): operand is ConditionOperand => {
  if (operand) {
    return Object.hasOwn(operand, 'operandType');
  }
  return false;
};

export const filterOperatorFormOptions = (
  leftOperandFieldValue: FieldValue,
  rightOperand: ConditionOperandValue,
  operator: DmOperator,
  symbolTable: SymbolTable,
): U21SelectOptionProps[] => {
  const leftOperand = getOperandFromSymbolTable(
    leftOperandFieldValue,
    symbolTable,
  );

  if (leftOperand && leftOperand.value.type === 'FIELD') {
    if (EMPTY_OPERATORS().has(operator)) {
      return OPERAND_TYPE_OPERATOR_OPTIONS_MAP.empty;
    }

    const { datatype: operandDataType } = leftOperand.value; // NOTE: we use operand.value.datatype for legacy rules
    const rightOperandType = rightOperand?.type;
    let operatorMapKey: string = operandDataType;
    if (operandDataType === 'select' || operandDataType === 'multiselect') {
      operatorMapKey = 'list';
    } else if (operandDataType === 'date' || operandDataType === 'datetime') {
      if (rightOperand && rightOperandType === 'DURATION')
        return OPERAND_TYPE_OPERATOR_OPTIONS_MAP.number.filter(
          (option) =>
            option.value !== ConditionalOperators.BETWEEN &&
            option.value !== ConditionalOperators.IS,
        );
      return OPERAND_TYPE_OPERATOR_OPTIONS_MAP[operatorMapKey];
    } else if (
      !Object.keys(OPERAND_TYPE_OPERATOR_OPTIONS_MAP).includes(operandDataType)
    ) {
      operatorMapKey = 'text';
    }

    return OPERAND_TYPE_OPERATOR_OPTIONS_MAP[operatorMapKey].filter(
      (option) => {
        switch (option.value) {
          case ConditionalOperators.EQ:
          case ConditionalOperators.NEQ:
          case ConditionalOperators.LIKE:
          case ConditionalOperators.ILIKE:
          case ConditionalOperators.NOT_LIKE:
          case ConditionalOperators.NOT_ILIKE:
          case ConditionalOperators.IN:
          case ConditionalOperators.NOT_IN:
            return (
              operandDataType !== 'multiselect' || operatorMapKey === 'list'
            );
          case ConditionalOperators.ANY_EQ:
          case ConditionalOperators.NONE_EQ:
          case ConditionalOperators.ANY_IN:
          case ConditionalOperators.NONE_IN:
          case ConditionalOperators.ANY_LIKE:
          case ConditionalOperators.NONE_LIKE:
            return operandDataType === 'multiselect';
          case ConditionalOperators.GT:
          case ConditionalOperators.GE:
          case ConditionalOperators.LT:
          case ConditionalOperators.LE:
          case ConditionalOperators.BETWEEN:
            return operandDataType === 'number';
          default:
            return false;
        }
      },
    );
  }
  return [];
};

export const MATCHLIST_COMPATIBLE_TYPES: string[] = [
  'string',
  'text',
  'enum',
  'list',
  'multiselect',
];

export const isListOperator = (operator: ConditionalOperators): boolean => {
  return [
    ConditionalOperators.ANY_IN,
    ConditionalOperators.IN,
    ConditionalOperators.NOT_IN,
    ConditionalOperators.NONE_IN,
  ].includes(operator);
};

export const getOperandFromSymbolTable = (
  fieldValue: QueryTreeNode | FieldValue | null,
  symbolTable: SymbolTable,
): FieldValueOperand | null => {
  let fieldSymbolKey: string = '';
  if (fieldValue?.type === 'FIELD') {
    if (/\.?(user|business)\./gi.test(fieldValue.field))
      fieldSymbolKey = fieldValue.field.replace(/(user|business)\./gi, '');
    else if (/external_id$/g.test(fieldValue.field)) {
      if (fieldValue.model === 'txn_instrument')
        fieldSymbolKey = fieldValue.field.replace(
          'external_id',
          'instrument_id',
        );
      else if (fieldValue.model === 'txn_event') {
        if (/instrument/g.test(fieldValue.field))
          fieldSymbolKey = fieldValue.field.replace(
            'external_id',
            'instrument_id',
          );
        else if (!/entity/g.test(fieldValue.field))
          fieldSymbolKey = fieldValue.field.replace('external_id', 'event_id');
        else fieldSymbolKey = fieldValue.field;
      } else if (fieldValue.model === 'action_event') {
        if (!/entity/g.test(fieldValue.field))
          fieldSymbolKey = fieldValue.field.replace('external_id', 'event_id');
        else fieldSymbolKey = fieldValue.field;
      } else fieldSymbolKey = fieldValue.field;
    } else if (/internal_fee$/g.test(fieldValue.field))
      fieldSymbolKey = fieldValue.field.replace('internal_fee', 'fee');
    else if (/internal_txn_type$/g.test(fieldValue.field))
      fieldSymbolKey = fieldValue.field.replace('internal_txn_type', 'type');
    else if (/internal_entity_type$/g.test(fieldValue.field))
      fieldSymbolKey = fieldValue.field.replace(
        'internal_entity_type',
        'entity_subtype',
      );
    else if (/zip_code$/g.test(fieldValue.field))
      fieldSymbolKey = fieldValue.field.replace('zip_code', 'postal_code');
    else if (/entity\.type$/g.test(fieldValue.field))
      fieldSymbolKey = fieldValue.field.replace('type', 'entity_type');
    else if (/usd_conversion_notes$/g.test(fieldValue.field))
      fieldSymbolKey = fieldValue.field.replace(
        'usd_conversion_notes',
        'conversion_notes',
      );
    else if (/\.name$/g.test(fieldValue.field)) {
      if (/entity/g.test(fieldValue.field) || fieldValue.model === 'entity')
        fieldSymbolKey = fieldValue.field.replace('name', 'business_name');
      else fieldSymbolKey = fieldValue.field;
    } else fieldSymbolKey = fieldValue.field;
  }

  const found = symbolTable[fieldSymbolKey];
  if (
    found?.type === 'FIELD' ||
    found?.type === 'VARIABLE' ||
    found?.type === 'fact_id'
  ) {
    return null;
  }
  return found ? found.value : null;
};

export const USE_DATA_PATH: Set<string> = new Set([
  'business_name',
  'entity_type',
  'entity_subtype',
  'instrument_id',
]);
const USE_FIELD: Set<string> = new Set(['ip_address', 'client_fingerprint']);
const USE_BASE_MODEL: Set<string> = new Set(['ENTITY', 'INSTRUMENT']);
const USE_MODEL: Set<string> = new Set(['entity', 'txn_instrument']);

interface Prefix {
  key: string;
  label: string;
}

const generateNativeDataSymbols = (
  symbolTable: SymbolTable,
  builderType: 'dropdown' | 'advanced',
  baseModel: DmbQueryBuilderOption,
  model: DmbQueryBuilderOptionCode,
  { key: prefixKey, label: prefixLabel }: Prefix,
  nativeDataType: OperandType,
  dropdownValues: U21SelectOptionProps[],
  dataSetting: NativeDataSettingsConfigResponse,
): SymbolTable => {
  const newSymbolTable = symbolTable;
  const dataPath = rectifyNativeDataSettingKeyIfApplicable(dataSetting);

  const shouldAddModelPrefix: boolean = !(
    (prefixKey === 'txn_instrument' && baseModel === 'INSTRUMENT') ||
    prefixKey === baseModel.toLowerCase()
  );
  const shouldAddTxnDirectionPrefix: boolean =
    /(sender|receiver|perspective)_(entity|instrument)/gi.test(prefixKey);
  const shouldAddPrefix = shouldAddModelPrefix || shouldAddTxnDirectionPrefix;

  const {
    unit21_data_classifier: unit21DataClassifier,
    native_key: nativeKey,
    description: dataSettingDescription,
  } = dataSetting;

  let text = keyPathToLabel(dataSetting, false, ' / ');
  if (shouldAddPrefix) {
    text = `${prefixLabel[0].toUpperCase() + prefixLabel.slice(1)} / ${text}`;
  } else if (unit21DataClassifier === Unit21DataClassifier.ACTION_EVENT) {
    text = `Action / ${text}`;
  } else if (unit21DataClassifier === Unit21DataClassifier.EVENT) {
    text = `Transaction / ${text}`;
  } else if (unit21DataClassifier === Unit21DataClassifier.ENTITY) {
    text = `Entity / ${text}`;
  } else if (unit21DataClassifier === Unit21DataClassifier.INSTRUMENT) {
    text = `Instrument / ${text}`;
  }

  let modelOrBaseModel: string = model;
  let field = `${shouldAddPrefix ? `${prefixKey}.` : ''}${dataPath}`;
  if (USE_BASE_MODEL.has(baseModel)) {
    field = USE_DATA_PATH.has(nativeKey) ? dataPath : nativeKey;
    if (!USE_MODEL.has(model)) {
      field = `${prefixKey}.${field}`;
      modelOrBaseModel = baseModel.toLocaleLowerCase(); // NOTE: this is for cases like fieldvalue where field is `txn_event.address.city`, the model still needs to be `txn_event` and not `address`
    }
  }
  const nativeKeyOrField = USE_FIELD.has(nativeKey) ? field : nativeKey;
  let symbolKey = `${shouldAddPrefix ? `${prefixKey}.` : ''}${nativeKeyOrField}`;
  let symbolValue: SymbolFieldValue | OperandSymbol;

  if (builderType === 'dropdown') {
    symbolValue = {
      type: nativeDataType,
      text,
      value: {
        operandType: dropdownValues.length ? 'multiselect' : nativeDataType,
        unit21DataClassifier,
        value: {
          type: 'FIELD',
          datatype: nativeDataType === 'text' ? 'string' : nativeDataType,
          model: modelOrBaseModel,
          field,
          label: symbolKey, // used to find symbol
        },
        dropdownValues,
      },
      description: dataSettingDescription
        ? truncate(dataSettingDescription, {
            length: MAX_DROPDOWN_OPTION_DESCRIPTION_LENGTH,
          })
        : undefined,
    };
  } else {
    // Advanced builder logic
    symbolKey = `${shouldAddModelPrefix ? `${prefixKey}.` : ''}${dataPath}`;
    symbolValue = {
      type: 'FIELD',
      datatype: nativeDataType === 'text' ? 'string' : nativeDataType,
      model: modelOrBaseModel,
      field,
      label: text, // used as rendered text in code mirror
    };
  }

  newSymbolTable[symbolKey] = symbolValue;
  return newSymbolTable;
};

const FIELDS_NOT_SUPPORTED_IN_DM = [
  'address',
  'addresses',
  'client_fingerprints',
  'email_addresses',
  'ip_addresses',
  'phone_numbers',
  'physical_ids',
];

const SUPPORTED_DATA_CLASSIFIER: ReadonlySet<Unit21DataClassifier> = new Set([
  Unit21DataClassifier.ACTION_EVENT,
  Unit21DataClassifier.ADDRESS,
  Unit21DataClassifier.EVENT,
  Unit21DataClassifier.ENTITY,
  Unit21DataClassifier.INSTRUMENT,
]);

const PRIMITIVE_TYPES_NOT_SUPPORTED_IN_DM: Set<DataSettingFieldType> = new Set([
  DataSettingFieldType.ENTITY_REFERENCE,
  DataSettingFieldType.INSTRUMENT_REFERENCE,
  DataSettingFieldType.JSON,
]);

export const CLASSIFIER_SECTION_MAP: Record<
  Exclude<
    Unit21DataClassifier,
    | Unit21DataClassifier.ALERT
    | Unit21DataClassifier.CASE
    | Unit21DataClassifier.RULE
    | Unit21DataClassifier.WATCHLIST
  >,
  DmbQueryBuilderOptionCode
> = {
  [Unit21DataClassifier.ACTION_EVENT]: 'action_event',
  [Unit21DataClassifier.ADDRESS]: 'address',
  [Unit21DataClassifier.INSTRUMENT]: 'txn_instrument',
  [Unit21DataClassifier.EVENT]: 'txn_event',
  [Unit21DataClassifier.ENTITY]: 'entity',
};

export const CLASSIFIER_SECTION_MAP_FRIENDLY_NAME: Record<
  Exclude<
    Unit21DataClassifier,
    | Unit21DataClassifier.ALERT
    | Unit21DataClassifier.CASE
    | Unit21DataClassifier.RULE
    | Unit21DataClassifier.WATCHLIST
  >,
  DmbQueryBuilderOptionCodeFriendlyName
> = {
  [Unit21DataClassifier.ACTION_EVENT]: 'Action event',
  [Unit21DataClassifier.ADDRESS]: 'Address',
  [Unit21DataClassifier.INSTRUMENT]: 'Instrument',
  [Unit21DataClassifier.EVENT]: 'Transaction event',
  [Unit21DataClassifier.ENTITY]: 'Entity',
};

const rectifyNativeDataSettingKeyIfApplicable = (
  dataSetting: NativeDataSettingsConfigResponse,
): string => {
  let overrideField =
    NATIVE_DATA_SETTINGS_NATIVE_KEY_MISMATCH_MAP[
      dataSetting.unit21_data_classifier
    ][dataSetting.native_key];
  if (dataSetting.native_key === 'ip_address') {
    // NOTE: ip_address condition is unique to DMB context
    overrideField = 'ip_address.ip_address';
  }
  if (dataSetting.native_key === 'client_fingerprint') {
    // NOTE: client_fingerprint condition is unique to DMB context
    overrideField = 'client_fingerprint.client_fingerprint';
  }
  return overrideField || dataSetting.native_key;
};

const SENDER_RECEIVER = ['sender', 'receiver'];

const entityPerspectiveToName = (entityPerspective: OrgDataSettingsConfig) => {
  const friendlyName = keyPathToLabel(entityPerspective, false, ' / ');
  return `${friendlyName} Entity`;
};

const filterFocsecFieldsIfDisabled = (
  shouldUseFocsecFields: boolean,
  key: string,
): boolean => {
  if (shouldUseFocsecFields) {
    return true;
  }
  return !['ip_is_vpn', 'ip_is_proxy', 'ip_is_tor', 'ip_is_bot'].includes(key);
};

export const REACH_OUT_TEXT =
  'Please reach out to your Customer Success Manager, or';

export const SUPPORT_REQUEST_LINK = (
  <U21Button
    color="primary"
    href="https://support.unit21.ai/hc/en-us/requests/new"
    target="_blank"
    rel="noopener noreferrer"
    variant="text"
  >
    send a request
  </U21Button>
);

export const RISK_RATINGS_TOOLTIP = (
  <p>
    Customer Risk Ratings must be enabled to use this feature. {REACH_OUT_TEXT}{' '}
    {SUPPORT_REQUEST_LINK} to our Support team to learn more.
  </p>
);

export const mergeSymbolTableWithNativeData = (
  symbolTable: SymbolTable,
  nativeData: NativeDataSettingsConfigResponse[],
  baseModel: DmbQueryBuilderOption,
  builderType: 'dropdown' | 'advanced',
  shouldUseEventSchemaV2Symbols: boolean,
  shouldUseFocsecFields: boolean,
  entityPerspectives: OrgDataSettingsConfig[],
  isRiskRatingsEnabled: boolean,
  hasActiveRiskRatingModel: boolean,
): SymbolTable => {
  let newSymbolTable: SymbolTable | undefined;
  nativeData
    .filter((dataSetting) => {
      // NOTE: native data fields that DMB doesn't support (yet?)
      return (
        !FIELDS_NOT_SUPPORTED_IN_DM.includes(dataSetting.native_key) &&
        !PRIMITIVE_TYPES_NOT_SUPPORTED_IN_DM.has(dataSetting.data_type) &&
        SUPPORTED_DATA_CLASSIFIER.has(dataSetting.unit21_data_classifier)
      );
    })
    .forEach((dataSetting) => {
      const {
        unit21_data_classifier: unit21DataClassifier,
        data_type: dataType,
        enum_set: enumSet,
      } = dataSetting;
      const lowerCasedClassifier: string = unit21DataClassifier.toLowerCase();
      const nativeDataType = DATATYPE_MAP[dataType] || 'text';

      let dropdownValues: U21SelectOptionProps[] = [];
      if (dataType === DataSettingFieldType.ENUM && enumSet) {
        dropdownValues = enumSet.map((enumValue) => {
          return {
            text: enumValue,
            value: enumValue,
          };
        });
      }

      if (baseModel === 'TXN_EVENT') {
        if (
          unit21DataClassifier === Unit21DataClassifier.ENTITY ||
          unit21DataClassifier === Unit21DataClassifier.INSTRUMENT
        ) {
          if (
            unit21DataClassifier === Unit21DataClassifier.ENTITY &&
            shouldUseEventSchemaV2Symbols
          ) {
            entityPerspectives.forEach((entityPerspective) => {
              newSymbolTable = generateNativeDataSymbols(
                symbolTable,
                builderType,
                baseModel,
                DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
                {
                  key: `perspective_entity.${entityPerspective.id}`,
                  label: `Entity / ${toSentenceCase(
                    entityPerspectiveToName(entityPerspective),
                  )}`,
                },
                nativeDataType,
                dropdownValues,
                dataSetting,
              );
            });
          } else {
            SENDER_RECEIVER.forEach((direction) => {
              newSymbolTable = generateNativeDataSymbols(
                symbolTable,
                builderType,
                baseModel,
                DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
                {
                  key: `${direction}_${lowerCasedClassifier}`,
                  label: `${CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier]} / ${toSentenceCase(direction)} ${lowerCasedClassifier}`,
                },
                nativeDataType,
                dropdownValues,
                dataSetting,
              );
            });
          }
        } else if (unit21DataClassifier === Unit21DataClassifier.ADDRESS) {
          // note: txn_event.address fields
          newSymbolTable = generateNativeDataSymbols(
            symbolTable,
            builderType,
            baseModel,
            DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
            {
              key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
              label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
            },
            nativeDataType,
            dropdownValues,
            dataSetting,
          );

          if (shouldUseEventSchemaV2Symbols) {
            entityPerspectives.forEach((entityPerspective) => {
              if (newSymbolTable) {
                newSymbolTable = generateNativeDataSymbols(
                  newSymbolTable,
                  builderType,
                  baseModel,
                  DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
                  {
                    key: `perspective_entity.${entityPerspective.id}.address`,
                    label: `Entity / ${toSentenceCase(entityPerspectiveToName(entityPerspective))} / Address`,
                  },
                  nativeDataType,
                  dropdownValues,
                  dataSetting,
                );
              }
            });
          } else {
            SENDER_RECEIVER.forEach((direction) => {
              if (newSymbolTable) {
                // NOTE: {sender/receiver}_entity.address fields
                newSymbolTable = generateNativeDataSymbols(
                  newSymbolTable,
                  builderType,
                  baseModel,
                  DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
                  {
                    key: `${direction}_entity.address`,
                    label: `Entity / ${toSentenceCase(direction)} entity / Address`,
                  },
                  nativeDataType,
                  dropdownValues,
                  dataSetting,
                );
              }
            });
          }
          SENDER_RECEIVER.forEach((direction) => {
            if (newSymbolTable) {
              // NOTE: {sender/receiver}_instrument.address fields
              newSymbolTable = generateNativeDataSymbols(
                newSymbolTable,
                builderType,
                baseModel,
                DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
                {
                  key: `${direction}_instrument.address`,
                  label: `Instrument / ${toSentenceCase(direction)} instrument / Address`,
                },
                nativeDataType,
                dropdownValues,
                dataSetting,
              );
            }
          });
        } else if (unit21DataClassifier === Unit21DataClassifier.EVENT) {
          newSymbolTable = generateNativeDataSymbols(
            symbolTable,
            builderType,
            baseModel,
            CLASSIFIER_SECTION_MAP[unit21DataClassifier],
            {
              key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
              label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
            },
            nativeDataType,
            dropdownValues,
            dataSetting,
          );
        }
      } else if (baseModel === Unit21DataClassifier.ACTION_EVENT) {
        if (
          unit21DataClassifier === Unit21DataClassifier.ENTITY ||
          unit21DataClassifier === Unit21DataClassifier.INSTRUMENT
        ) {
          newSymbolTable = generateNativeDataSymbols(
            symbolTable,
            builderType,
            baseModel,
            DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
            {
              key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
              label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
            },
            nativeDataType,
            dropdownValues,
            dataSetting,
          );
        } else if (unit21DataClassifier === Unit21DataClassifier.ADDRESS) {
          // NOTE: action_event.address fields
          newSymbolTable = generateNativeDataSymbols(
            symbolTable,
            builderType,
            baseModel,
            DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
            {
              key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
              label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
            },
            nativeDataType,
            dropdownValues,
            dataSetting,
          );
          ['entity', 'txn_instrument'].forEach((dmObjectType) => {
            // NOTE: action_event.entity/txn_instrument.address fields
            const dmObjectTypeLabel =
              dmObjectType === 'entity' ? 'Entity' : 'Instrument';
            if (newSymbolTable) {
              newSymbolTable = generateNativeDataSymbols(
                newSymbolTable,
                builderType,
                baseModel,
                DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
                {
                  key: `${dmObjectType}.address`,
                  label: `${dmObjectTypeLabel} / Address`,
                },
                nativeDataType,
                dropdownValues,
                dataSetting,
              );
            }
          });
        } else if (unit21DataClassifier === Unit21DataClassifier.ACTION_EVENT) {
          newSymbolTable = generateNativeDataSymbols(
            symbolTable,
            builderType,
            baseModel,
            CLASSIFIER_SECTION_MAP[unit21DataClassifier],
            {
              key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
              label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
            },
            nativeDataType,
            dropdownValues,
            dataSetting,
          );
        }
      } else if (
        (baseModel === Unit21DataClassifier.ENTITY &&
          (unit21DataClassifier === Unit21DataClassifier.ADDRESS ||
            unit21DataClassifier === Unit21DataClassifier.ENTITY)) ||
        (baseModel === Unit21DataClassifier.INSTRUMENT &&
          (unit21DataClassifier === Unit21DataClassifier.ADDRESS ||
            unit21DataClassifier === Unit21DataClassifier.INSTRUMENT))
      ) {
        newSymbolTable = generateNativeDataSymbols(
          symbolTable,
          builderType,
          baseModel,
          CLASSIFIER_SECTION_MAP[unit21DataClassifier],
          {
            key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
            label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
          },
          nativeDataType,
          dropdownValues,
          dataSetting,
        );
      }
    });

  // NOTE: [SC-62244]. hard coded entries to support legacy code, need to coordinate with DIM team to support these, then remove this block of code
  // sender_instrument.external_id, receiver_instrument.external_id (model txn_event)
  const oldOrNewSymbolTable = newSymbolTable || symbolTable;
  if (baseModel === 'ENTITY') {
    if (builderType === 'advanced') {
      if (isRiskRatingsEnabled || hasActiveRiskRatingModel) {
        addLatestRiskFieldsToEventForAdvancedBuilder(
          oldOrNewSymbolTable,
          null,
          Unit21DataClassifier.ENTITY,
        );
      }
      oldOrNewSymbolTable.cumulative_transaction_sum = {
        type: 'FIELD',
        field: 'cumulative_transaction_sum',
        label: 'Cumulative transaction sum',
        model: 'entity',
        datatype: 'number',
        cardinality: 'many',
      };
      oldOrNewSymbolTable.first_transaction = {
        type: 'FIELD',
        field: 'first_transaction',
        label: 'First transaction',
        model: 'entity',
        datatype: 'datetime',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['client_fingerprint.client_fingerprint'] = {
        type: 'FIELD',
        field: 'client_fingerprint.client_fingerprint',
        label: 'Client fingerprint',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['ip_address.ip_address'] = {
        type: 'FIELD',
        field: 'ip_address.ip_address',
        label: 'IP address',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
      if (shouldUseFocsecFields) {
        oldOrNewSymbolTable['ip_address.ip_is_bot'] = {
          type: 'FIELD',
          field: 'ip_address.ip_is_bot',
          label: 'IP is bot',
          model: 'entity',
          datatype: 'boolean',
          cardinality: 'many',
        };
        oldOrNewSymbolTable['ip_address.ip_is_vpn'] = {
          type: 'FIELD',
          field: 'ip_address.ip_is_vpn',
          label: 'IP uses VPN',
          model: 'entity',
          datatype: 'boolean',
          cardinality: 'many',
        };
        oldOrNewSymbolTable['ip_address.ip_is_proxy'] = {
          type: 'FIELD',
          field: 'ip_address.ip_is_proxy',
          label: 'IP uses proxy',
          model: 'entity',
          datatype: 'boolean',
          cardinality: 'many',
        };
        oldOrNewSymbolTable['ip_address.ip_is_tor'] = {
          type: 'FIELD',
          field: 'ip_address.ip_is_tor',
          label: 'IP uses Tor',
          model: 'entity',
          datatype: 'boolean',
          cardinality: 'many',
        };
      }
      oldOrNewSymbolTable['phone_number.number'] = {
        type: 'FIELD',
        field: 'phone_number.number',
        label: 'Phone number / Number',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['email_address.email_address'] = {
        type: 'FIELD',
        field: 'email_address.email_address',
        label: 'Email address',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable.risk_score = {
        // Although risk score is deprecated, keep the symbol to ensure old rules  displayed correctly.
        type: 'FIELD',
        field: 'risk_score',
        label: 'Risk score',
        model: 'entity',
        datatype: 'number',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.country'] = {
        type: 'FIELD',
        field: 'geolocation.country',
        label: 'Geolocation / Country',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.city'] = {
        type: 'FIELD',
        field: 'geolocation.city',
        label: 'Geolocation / City',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.postal_code'] = {
        type: 'FIELD',
        field: 'geolocation.postal_code',
        label: 'Geolocation / Postal code',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.longitude'] = {
        type: 'FIELD',
        field: 'geolocation.longitude',
        label: 'Geolocation / Longitude',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.latitude'] = {
        type: 'FIELD',
        field: 'geolocation.latitude',
        label: 'Geolocation / Latitude',
        model: 'entity',
        datatype: 'string',
        cardinality: 'many',
      };
    } else {
      oldOrNewSymbolTable.cumulative_transaction_sum = {
        type: 'text',
        text: 'Cumulative transaction sum',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'cumulative_transaction_sum',
            label: 'cumulative_transaction_sum',
            model: 'entity',
            datatype: 'number',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable.first_transaction = {
        type: 'text',
        text: 'First transaction',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'first_transaction',
            label: 'first_transaction',
            model: 'entity',
            datatype: 'datetime',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['phone_number.number'] = {
        type: 'text',
        text: 'Phone number / Number',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'phone_number.number',
            label: 'phone_number.number',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['client_fingerprint.client_fingerprint'] = {
        type: 'text',
        text: 'Client fingerprint',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'client_fingerprint.client_fingerprint',
            label: 'client_fingerprint.client_fingerprint',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['email_address.email_address'] = {
        type: 'text',
        text: 'Email address',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'email_address.email_address',
            label: 'email_address.email_address',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.country'] = {
        type: 'text',
        text: 'Geolocation / Country',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'geolocation.country',
            label: 'geolocation.country',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.city'] = {
        type: 'text',
        text: 'Geolocation / City',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'geolocation.city',
            label: 'geolocation.city',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.postal_code'] = {
        type: 'text',
        text: 'Geolocation / Postal code',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'geolocation.postal_code',
            label: 'geolocation.postal_code',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.longitude'] = {
        type: 'text',
        text: 'Geolocation / Longitude',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'geolocation.longitude',
            label: 'geolocation.longitude',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.latitude'] = {
        type: 'text',
        text: 'Geolocation / Latitude',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'geolocation.latitude',
            label: 'geolocation.latitude',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      if (shouldUseFocsecFields) {
        oldOrNewSymbolTable['ip_address.ip_is_bot'] = {
          type: 'text',
          text: 'IP uses bot',
          value: {
            operandType: 'boolean',
            unit21DataClassifier: Unit21DataClassifier.ENTITY,
            value: {
              type: 'FIELD',
              field: 'ip_address.ip_is_bot',
              label: 'IP uses bot',
              model: 'entity',
              datatype: 'boolean',
              cardinality: 'many',
            },
          },
        };
        oldOrNewSymbolTable['ip_address.ip_is_vpn'] = {
          type: 'text',
          text: 'IP uses VPN',
          value: {
            operandType: 'boolean',
            unit21DataClassifier: Unit21DataClassifier.ENTITY,
            value: {
              type: 'FIELD',
              field: 'ip_address.ip_is_vpn',
              label: 'IP uses VPN',
              model: 'entity',
              datatype: 'boolean',
              cardinality: 'many',
            },
          },
        };
        oldOrNewSymbolTable['ip_address.ip_is_proxy'] = {
          type: 'text',
          text: 'IP uses proxy',
          value: {
            operandType: 'boolean',
            unit21DataClassifier: Unit21DataClassifier.ENTITY,
            value: {
              type: 'FIELD',
              field: 'ip_address.ip_is_proxy',
              label: 'IP uses proxy',
              model: 'entity',
              datatype: 'boolean',
              cardinality: 'many',
            },
          },
        };
        oldOrNewSymbolTable['ip_address.ip_is_tor'] = {
          type: 'text',
          text: 'IP uses Tor',
          value: {
            operandType: 'boolean',
            unit21DataClassifier: Unit21DataClassifier.ENTITY,
            value: {
              type: 'FIELD',
              field: 'ip_address.ip_is_tor',
              label: 'IP uses Tor',
              model: 'entity',
              datatype: 'boolean',
              cardinality: 'many',
            },
          },
        };
      }
      oldOrNewSymbolTable['ip_address.ip_address'] = {
        type: 'text',
        text: 'IP address',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.ENTITY,
          value: {
            type: 'FIELD',
            field: 'ip_address.ip_address',
            label: 'IP address',
            model: 'entity',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      addLatestRiskFieldsToEventForDropdownBuilder(
        oldOrNewSymbolTable,
        null,
        Unit21DataClassifier.ENTITY,
        isRiskRatingsEnabled,
        hasActiveRiskRatingModel,
      );
    }
  }
  if (baseModel === 'INSTRUMENT') {
    if (builderType === 'advanced') {
      oldOrNewSymbolTable.gateway = {
        type: 'FIELD',
        field: 'gateway',
        label: 'Gateway',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable.parent_id = {
        type: 'FIELD',
        field: 'parent_id',
        label: 'Parent ID',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['client_fingerprint.client_fingerprint'] = {
        type: 'FIELD',
        field: 'client_fingerprint.client_fingerprint',
        label: 'Client fingerprint',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['ip_address.ip_address'] = {
        type: 'FIELD',
        field: 'ip_address.ip_address',
        label: 'IP address',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
      if (shouldUseFocsecFields) {
        oldOrNewSymbolTable['ip_address.ip_is_bot'] = {
          type: 'FIELD',
          field: 'ip_address.ip_is_bot',
          label: 'IP uses bot',
          model: 'txn_instrument',
          datatype: 'boolean',
          cardinality: 'many',
        };
        oldOrNewSymbolTable['ip_address.ip_is_vpn'] = {
          type: 'FIELD',
          field: 'ip_address.ip_is_vpn',
          label: 'IP uses VPN',
          model: 'txn_instrument',
          datatype: 'boolean',
          cardinality: 'many',
        };
        oldOrNewSymbolTable['ip_address.ip_is_proxy'] = {
          type: 'FIELD',
          field: 'ip_address.ip_is_proxy',
          label: 'IP uses proxy',
          model: 'txn_instrument',
          datatype: 'boolean',
          cardinality: 'many',
        };
        oldOrNewSymbolTable['ip_address.ip_is_tor'] = {
          type: 'FIELD',
          field: 'ip_address.ip_is_tor',
          label: 'IP uses Tor',
          model: 'txn_instrument',
          datatype: 'boolean',
          cardinality: 'many',
        };
      }
      oldOrNewSymbolTable['geolocation.country'] = {
        type: 'FIELD',
        field: 'geolocation.country',
        label: 'Geolocation / Country',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.city'] = {
        type: 'FIELD',
        field: 'geolocation.city',
        label: 'Geolocation / City',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.postal_code'] = {
        type: 'FIELD',
        field: 'geolocation.postal_code',
        label: 'Geolocation / Postal code',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.longitude'] = {
        type: 'FIELD',
        field: 'geolocation.longitude',
        label: 'Geolocation / Longitude',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.latitude'] = {
        type: 'FIELD',
        field: 'geolocation.latitude',
        label: 'Geolocation / Latitude',
        model: 'txn_instrument',
        datatype: 'string',
        cardinality: 'many',
      };
    } else {
      oldOrNewSymbolTable.gateway = {
        type: 'text',
        text: 'Gateway',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'gateway',
            label: 'gateway',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable.parent_id = {
        type: 'text',
        text: 'Parent ID',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'parent_id',
            label: 'parent_id',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['client_fingerprint.client_fingerprint'] = {
        type: 'text',
        text: 'Client fingerprint',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'client_fingerprint.client_fingerprint',
            label: 'client_fingerprint.client_fingerprint',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['ip_address.ip_address'] = {
        type: 'text',
        text: 'IP address',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'ip_address.ip_address',
            label: 'IP address',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      if (shouldUseFocsecFields) {
        oldOrNewSymbolTable['ip_address.ip_is_bot'] = {
          type: 'text',
          text: 'IP uses bot',
          value: {
            operandType: 'boolean',
            unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
            value: {
              type: 'FIELD',
              field: 'ip_address.ip_is_bot',
              label: 'IP uses bot',
              model: 'txn_instrument',
              datatype: 'boolean',
              cardinality: 'many',
            },
          },
        };
        oldOrNewSymbolTable['ip_address.ip_is_vpn'] = {
          type: 'text',
          text: 'IP uses VPN',
          value: {
            operandType: 'boolean',
            unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
            value: {
              type: 'FIELD',
              field: 'ip_address.ip_is_vpn',
              label: 'IP uses VPN',
              model: 'txn_instrument',
              datatype: 'boolean',
              cardinality: 'many',
            },
          },
        };
        oldOrNewSymbolTable['ip_address.ip_is_proxy'] = {
          type: 'text',
          text: 'IP uses proxy',
          value: {
            operandType: 'boolean',
            unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
            value: {
              type: 'FIELD',
              field: 'ip_address.ip_is_proxy',
              label: 'IP uses proxy',
              model: 'txn_instrument',
              datatype: 'boolean',
              cardinality: 'many',
            },
          },
        };
        oldOrNewSymbolTable['ip_address.ip_is_tor'] = {
          type: 'text',
          text: 'IP uses tor',
          value: {
            operandType: 'boolean',
            unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
            value: {
              type: 'FIELD',
              field: 'ip_address.ip_is_tor',
              label: 'IP uses Tor',
              model: 'txn_instrument',
              datatype: 'boolean',
              cardinality: 'many',
            },
          },
        };
      }
      oldOrNewSymbolTable['geolocation.country'] = {
        type: 'text',
        text: 'Geolocation / Country',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'geolocation.country',
            label: 'geolocation.country',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.city'] = {
        type: 'text',
        text: 'Geolocation / City',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'geolocation.city',
            label: 'geolocation.city',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.postal_code'] = {
        type: 'text',
        text: 'Geolocation / Postal code',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'geolocation.postal_code',
            label: 'geolocation.postal_code',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.longitude'] = {
        type: 'text',
        text: 'Geolocation / Longitude',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'geolocation.longitude',
            label: 'geolocation.longitude',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.latitude'] = {
        type: 'text',
        text: 'Geolocation / Latitude',
        value: {
          operandType: 'text',
          unit21DataClassifier: Unit21DataClassifier.INSTRUMENT,
          value: {
            type: 'FIELD',
            field: 'geolocation.latitude',
            label: 'geolocation.latitude',
            model: 'txn_instrument',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
    }
  }
  if (baseModel === 'DEVICE') {
    if (builderType === 'advanced') {
      oldOrNewSymbolTable.os_name = {
        type: 'FIELD',
        field: 'os_name',
        label: 'OS name',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable.network_carrier = {
        type: 'FIELD',
        field: 'network_carrier',
        label: 'Network carrier',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable.network_cellular = {
        type: 'FIELD',
        field: 'network_cellular',
        label: 'Network cellular',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable.model = {
        type: 'FIELD',
        field: 'model',
        label: 'Model',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable.manufacturer = {
        type: 'FIELD',
        field: 'manufacturer',
        label: 'Manufacturer',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable.app_version = {
        type: 'FIELD',
        field: 'app_version',
        label: 'App version',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['ip_address.ip_address'] = {
        type: 'FIELD',
        field: 'ip_address.ip_address',
        label: 'IP address',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['phone_number.number'] = {
        type: 'FIELD',
        field: 'phone_number.number',
        label: 'Phone number / Number',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['email_address.email_address'] = {
        type: 'FIELD',
        field: 'email_address.email_address',
        label: 'Email address',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.country'] = {
        type: 'FIELD',
        field: 'geolocation.country',
        label: 'Geolocation / Country',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.city'] = {
        type: 'FIELD',
        field: 'geolocation.city',
        label: 'Geolocation / City',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.postal_code'] = {
        type: 'FIELD',
        field: 'geolocation.postal_code',
        label: 'Geolocation / Postal code',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.longitude'] = {
        type: 'FIELD',
        field: 'geolocation.longitude',
        label: 'Geolocation / Longitude',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
      oldOrNewSymbolTable['geolocation.latitude'] = {
        type: 'FIELD',
        field: 'geolocation.latitude',
        label: 'Geolocation / Latitude',
        model: 'device',
        datatype: 'string',
        cardinality: 'many',
      };
    } else {
      oldOrNewSymbolTable.os_name = {
        type: 'text',
        text: 'OS name',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'os_name',
            label: 'os_name',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable.network_carrier = {
        type: 'text',
        text: 'Network carrier',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'network_carrier',
            label: 'network_carrier',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable.network_cellular = {
        type: 'text',
        text: 'Network cellular',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'network_cellular',
            label: 'network_cellular',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable.model = {
        type: 'text',
        text: 'Model',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'model',
            label: 'model',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable.manufacturer = {
        type: 'text',
        text: 'Manufacturer',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'manufacturer',
            label: 'manufacturer',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable.app_version = {
        type: 'text',
        text: 'App version',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'app_version',
            label: 'app_version',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['ip_address.ip_address'] = {
        type: 'text',
        text: 'IP address',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'ip_address.ip_address',
            label: 'ip_address.ip_address',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['phone_number.number'] = {
        type: 'text',
        text: 'Phone number / Number',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'phone_number.number',
            label: 'phone_number.number',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['email_address.email_address'] = {
        type: 'text',
        text: 'Email address',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'email_address.email_address',
            label: 'email_address.email_address',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.country'] = {
        type: 'text',
        text: 'Geolocation / Country',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'geolocation.country',
            label: 'geolocation.country',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.city'] = {
        type: 'text',
        text: 'Geolocation / City',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'geolocation.city',
            label: 'geolocation.city',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.postal_code'] = {
        type: 'text',
        text: 'Geolocation / Postal code',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'geolocation.postal_code',
            label: 'geolocation.postal_code',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.longitude'] = {
        type: 'text',
        text: 'Geolocation / Longitude',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'geolocation.longitude',
            label: 'geolocation.longitude',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
      oldOrNewSymbolTable['geolocation.latitude'] = {
        type: 'text',
        text: 'Geolocation / Latitude',
        value: {
          operandType: 'text',
          value: {
            type: 'FIELD',
            field: 'geolocation.latitude',
            label: 'geolocation.latitude',
            model: 'device',
            datatype: 'string',
            cardinality: 'many',
          },
        },
      };
    }
  }
  if (baseModel === 'TXN_EVENT') {
    if (builderType === 'advanced') {
      if (isRiskRatingsEnabled || hasActiveRiskRatingModel) {
        SENDER_RECEIVER.forEach((direction) => {
          addLatestRiskFieldsToEventForAdvancedBuilder(
            oldOrNewSymbolTable,
            {
              key: `${direction}_entity`,
              label: `Entity / ${toSentenceCase(direction)} entity`,
            },
            Unit21DataClassifier.EVENT,
          );
        });
      }
      for (const [key, value] of Object.entries(
        TXN_EVENT_COMMON_ADVANCED_SYMBOLS,
      ).filter(([fieldKey]) =>
        filterFocsecFieldsIfDisabled(shouldUseFocsecFields, fieldKey),
      )) {
        oldOrNewSymbolTable[key] = value;
      }
      for (const [key, value] of Object.entries(
        TXN_EVENT_SENDER_RECEIVER_ADVANCED_SYMBOLS,
      )) {
        oldOrNewSymbolTable[key] = value;
      }
      SENDER_RECEIVER.forEach((direction) => {
        addNestedGeolocationFieldsForAdvancedBuilder(oldOrNewSymbolTable, {
          key: `${direction}_entity`,
          label: `Entity / ${toSentenceCase(direction)} entity`,
        });
        addNestedGeolocationFieldsForAdvancedBuilder(oldOrNewSymbolTable, {
          key: `${direction}_instrument`,
          label: `Instrument / ${toSentenceCase(direction)} instrument`,
        });
      });
    } else {
      // TODO(SC-69437): Support hybrid property with EventSchema v2
      if (!shouldUseEventSchemaV2Symbols) {
        for (const [key, value] of Object.entries(
          TXN_EVENT_SENDER_RECEIVER_ENTITY_DROPDOWN_SYMBOLS,
        ).filter(([fieldKey]) =>
          filterFocsecFieldsIfDisabled(shouldUseFocsecFields, fieldKey),
        )) {
          oldOrNewSymbolTable[key] = value;
        }
      }
      for (const [key, value] of Object.entries(
        TXN_EVENT_COMMON_DROPDOWN_SYMBOLS,
      ).filter(([fieldKey]) =>
        filterFocsecFieldsIfDisabled(shouldUseFocsecFields, fieldKey),
      )) {
        oldOrNewSymbolTable[key] = value;
      }
      if (shouldUseEventSchemaV2Symbols) {
        entityPerspectives.forEach((perspective) => {
          addNestedGeolocationFieldsForDropdownBuilder(oldOrNewSymbolTable, {
            key: `perspective_entity.${perspective.id}`,
            label: `Entity / ${entityPerspectiveToName(perspective)}`,
          });
        });
      } else {
        SENDER_RECEIVER.forEach((direction) => {
          addNestedGeolocationFieldsForDropdownBuilder(oldOrNewSymbolTable, {
            key: `${direction}_entity`,
            label: `Entity / ${toSentenceCase(direction)} entity`,
          });
        });
      }
      SENDER_RECEIVER.forEach((direction) => {
        addNestedGeolocationFieldsForDropdownBuilder(oldOrNewSymbolTable, {
          key: `${direction}_instrument`,
          label: `Instrument / ${toSentenceCase(direction)} instrument`,
        });
      });
      SENDER_RECEIVER.forEach((direction) => {
        addLatestRiskFieldsToEventForDropdownBuilder(
          oldOrNewSymbolTable,
          {
            key: `${direction}_entity`,
            label: `Entity / ${toSentenceCase(direction)} entity`,
          },
          Unit21DataClassifier.EVENT,
          isRiskRatingsEnabled,
          hasActiveRiskRatingModel,
        );
      });
    }
  }
  if (baseModel === 'ACTION_EVENT') {
    if (builderType === 'advanced') {
      if (isRiskRatingsEnabled || hasActiveRiskRatingModel) {
        addLatestRiskFieldsToEventForAdvancedBuilder(
          oldOrNewSymbolTable,
          {
            key: 'entity',
            label: 'Entity',
          },
          Unit21DataClassifier.ACTION_EVENT,
        );
      }
      for (const [key, value] of Object.entries(
        ACTION_EVENT_ADVANCED_SYMBOLS,
      ).filter(([fieldKey]) =>
        filterFocsecFieldsIfDisabled(shouldUseFocsecFields, fieldKey),
      )) {
        oldOrNewSymbolTable[key] = value;
      }
    } else {
      for (const [key, value] of Object.entries(
        ACTION_EVENT_DROPDOWN_SYMBOLS,
      ).filter(([fieldKey]) =>
        filterFocsecFieldsIfDisabled(shouldUseFocsecFields, fieldKey),
      )) {
        oldOrNewSymbolTable[key] = value;
      }
      addLatestRiskFieldsToEventForDropdownBuilder(
        oldOrNewSymbolTable,
        {
          key: 'entity',
          label: 'Entity',
        },
        Unit21DataClassifier.ACTION_EVENT,
        isRiskRatingsEnabled,
        hasActiveRiskRatingModel,
      );
    }
  }
  return oldOrNewSymbolTable;
};

const generateCustomDataSymbols = (
  symbolTable: SymbolTable,
  builderType: 'dropdown' | 'advanced',
  baseModel: DmbQueryBuilderOption,
  model: DmbQueryBuilderOptionCode,
  { key: prefixKey, label: prefixLabel }: Prefix,
  dropdownValues: U21SelectOptionProps[],
  dataSetting: CustomDataSettingsConfigResponse,
): SymbolTable => {
  const newSymbolTable = symbolTable;
  const {
    data_type: dataType,
    key_path: keyPath,
    user_facing_label: userFacingLabel,
    unit21_data_classifier: unit21DataClassifier,
  } = dataSetting;
  const formattedModelPrefix: string = prefixKey;
  const shouldAddModelPrefix: boolean = !(
    (prefixKey === 'txn_instrument' && baseModel === 'INSTRUMENT') ||
    prefixKey === baseModel.toLowerCase()
  );
  const shouldAddTxnDirectionPrefix: boolean =
    /(sender|receiver|perspective)_(entity|instrument)/gi.test(prefixKey);
  const shouldAddPrefix = shouldAddModelPrefix || shouldAddTxnDirectionPrefix;
  const dataPathDotNotation = `custom_data.${keyPath.join('.')}`;
  const formattedField = `${
    shouldAddModelPrefix ? `${formattedModelPrefix}.` : ''
  }${dataPathDotNotation}`;

  let text = keyPathToLabel(dataSetting, false, ' / ');
  if (!userFacingLabel) {
    text = `Custom Data / ${text}`;
  }
  if (shouldAddTxnDirectionPrefix) {
    text = `${prefixLabel} / ${text}`;
  }

  // Add classifier-based prefix
  if (unit21DataClassifier === Unit21DataClassifier.ACTION_EVENT) {
    text = `Action / ${text}`;
  } else if (unit21DataClassifier === Unit21DataClassifier.EVENT) {
    text = `Transaction / ${text}`;
  } else if (unit21DataClassifier === Unit21DataClassifier.ENTITY) {
    text = `Entity / ${text}`;
  } else if (unit21DataClassifier === Unit21DataClassifier.INSTRUMENT) {
    text = `Instrument / ${text}`;
  }

  const field = `${shouldAddPrefix ? `${prefixKey}.` : ''}${dataPathDotNotation}`;
  let symbolValue: OperandSymbol | SymbolFieldValue;
  const customDataType = DATATYPE_MAP[dataType] || 'text';
  if (builderType === 'dropdown') {
    symbolValue = {
      type: customDataType,
      text,
      value: {
        operandType: dropdownValues.length ? 'multiselect' : customDataType,
        value: {
          type: 'FIELD',
          datatype: customDataType === 'text' ? 'string' : customDataType,
          model,
          field,
          label: formattedField,
        },
        dropdownValues,
      },
    };
  } else {
    symbolValue = {
      type: 'FIELD',
      datatype: customDataType === 'text' ? 'string' : customDataType,
      model,
      field,
      label: text, // Use the prefixed text for the advanced builder as well
    };
  }

  newSymbolTable[formattedField] = symbolValue;
  return newSymbolTable;
};

export const isNotEventListField = (cd: CustomDataSettingsConfigResponse) =>
  !(
    [Unit21DataClassifier.EVENT, Unit21DataClassifier.ACTION_EVENT].includes(
      cd.unit21_data_classifier,
    ) && cd.data_type === DataSettingFieldType.LIST
  );

export const mergeSymbolTableWithCustomData = (
  symbolTable: SymbolTable,
  customData: CustomDataSettingsConfigResponse[],
  baseModel: DmbQueryBuilderOption,
  builderType: 'dropdown' | 'advanced',
  shouldUseEventSchemaV2Symbols: boolean,
  entityPerspectives: OrgDataSettingsConfig[],
): SymbolTable => {
  let newSymbolTable: SymbolTable | undefined;
  customData
    .filter((dataSetting) => {
      return (
        SUPPORTED_DATA_CLASSIFIER.has(dataSetting.unit21_data_classifier) &&
        !PRIMITIVE_TYPES_NOT_SUPPORTED_IN_DM.has(dataSetting.data_type)
      );
    })
    .forEach((dataSetting) => {
      const {
        data_type: dataType,
        enum_set: enumSet,
        unit21_data_classifier: unit21DataClassifier,
      } = dataSetting;
      const lowerCasedClassifier: string = unit21DataClassifier.toLowerCase();

      let customDataDropdownValues: U21SelectOptionProps[] = [];
      if (dataType === 'ENUM' && enumSet) {
        customDataDropdownValues = enumSet.map((enumValue) => {
          return {
            text: enumValue,
            value: enumValue,
          };
        });
      }

      if (baseModel === 'TXN_EVENT') {
        if (
          unit21DataClassifier === Unit21DataClassifier.ENTITY ||
          unit21DataClassifier === Unit21DataClassifier.INSTRUMENT
        ) {
          if (
            shouldUseEventSchemaV2Symbols &&
            unit21DataClassifier === Unit21DataClassifier.ENTITY
          ) {
            entityPerspectives.forEach((perspective) => {
              newSymbolTable = generateCustomDataSymbols(
                symbolTable,
                builderType,
                baseModel,
                DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
                {
                  key: `perspective_entity.${perspective.id}`,
                  label: toSentenceCase(entityPerspectiveToName(perspective)),
                },
                customDataDropdownValues,
                dataSetting,
              );
            });
          } else {
            SENDER_RECEIVER.forEach((direction) => {
              newSymbolTable = generateCustomDataSymbols(
                symbolTable,
                builderType,
                baseModel,
                DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
                {
                  key: `${direction}_${lowerCasedClassifier}`,
                  label: `${toSentenceCase(direction)} ${lowerCasedClassifier}`,
                },
                customDataDropdownValues,
                dataSetting,
              );
            });
          }
        } else if (
          unit21DataClassifier === Unit21DataClassifier.EVENT ||
          unit21DataClassifier === Unit21DataClassifier.ADDRESS
        ) {
          newSymbolTable = generateCustomDataSymbols(
            symbolTable,
            builderType,
            baseModel,
            CLASSIFIER_SECTION_MAP[unit21DataClassifier],
            {
              key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
              label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
            },
            customDataDropdownValues,
            dataSetting,
          );
        }
      } else if (baseModel === Unit21DataClassifier.ACTION_EVENT) {
        if (
          unit21DataClassifier === Unit21DataClassifier.ENTITY ||
          unit21DataClassifier === Unit21DataClassifier.INSTRUMENT
        ) {
          newSymbolTable = generateCustomDataSymbols(
            symbolTable,
            builderType,
            baseModel,
            DM_OBJECT_ENUM_TO_CODE_MAP[baseModel],
            {
              key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
              label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
            },
            customDataDropdownValues,
            dataSetting,
          );
        } else if (
          unit21DataClassifier === Unit21DataClassifier.ACTION_EVENT ||
          unit21DataClassifier === Unit21DataClassifier.ADDRESS
        ) {
          newSymbolTable = generateCustomDataSymbols(
            symbolTable,
            builderType,
            baseModel,
            CLASSIFIER_SECTION_MAP[unit21DataClassifier],
            {
              key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
              label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
            },
            customDataDropdownValues,
            dataSetting,
          );
        }
      } else if (
        (baseModel === Unit21DataClassifier.ENTITY &&
          (unit21DataClassifier === Unit21DataClassifier.ENTITY ||
            unit21DataClassifier === Unit21DataClassifier.ADDRESS)) ||
        (baseModel === Unit21DataClassifier.INSTRUMENT &&
          (unit21DataClassifier === Unit21DataClassifier.INSTRUMENT ||
            unit21DataClassifier === Unit21DataClassifier.ADDRESS))
      ) {
        newSymbolTable = generateCustomDataSymbols(
          symbolTable,
          builderType,
          baseModel,
          CLASSIFIER_SECTION_MAP[unit21DataClassifier],
          {
            key: CLASSIFIER_SECTION_MAP[unit21DataClassifier],
            label: CLASSIFIER_SECTION_MAP_FRIENDLY_NAME[unit21DataClassifier],
          },
          customDataDropdownValues,
          dataSetting,
        );
      }
    });
  return newSymbolTable || symbolTable;
};

export const mergeSymbolTableWithDataSettings = (
  nativeData: NativeDataSettingsConfigResponse[],
  customData: CustomDataSettingsConfigResponse[],
  baseModel: DmbQueryBuilderOption,
  builderType: 'dropdown' | 'advanced',
  shouldUseEventSchemaV2Symbols: boolean = false,
  shouldUseFocsecFields: boolean = false,
  entityPerspectives: OrgDataSettingsConfig[] = [],
  isRiskRatingsEnabled: boolean = false,
  hasActiveRiskRatingModel: boolean = false,
): SymbolTable => {
  let symbolTable: SymbolTable = {};
  if (nativeData)
    symbolTable = mergeSymbolTableWithNativeData(
      symbolTable,
      nativeData,
      baseModel,
      builderType,
      shouldUseEventSchemaV2Symbols,
      shouldUseFocsecFields,
      entityPerspectives,
      isRiskRatingsEnabled,
      hasActiveRiskRatingModel,
    );
  if (customData)
    symbolTable = mergeSymbolTableWithCustomData(
      symbolTable,
      customData,
      baseModel,
      builderType,
      shouldUseEventSchemaV2Symbols,
      entityPerspectives,
    );

  return symbolTable;
};

const deriveModel = (classifier: string) => {
  if (classifier === Unit21DataClassifier.INSTRUMENT) {
    return 'txn_instrument';
  }
  if (classifier === Unit21DataClassifier.EVENT) {
    return 'txn_event';
  }

  return classifier.toLowerCase();
};

export const transformCustomDataSettingsValueToFieldValue = (
  customData: CustomDataSettingsConfigResponse,
): FieldValue => {
  return {
    datatype: DATATYPE_MAP[customData.data_type],
    field: `custom_data.${customData.key_path[0]}`,
    type: 'FIELD',
    model: deriveModel(customData.unit21_data_classifier),
  };
};

export const transformNativeDataSettingsValueToFieldValue = (
  nativeData: NativeDataSettingsConfigResponse,
): FieldValue => {
  return {
    datatype: DATATYPE_MAP[nativeData.data_type],
    field: nativeData.native_key,
    type: 'FIELD',
    model: deriveModel(nativeData.unit21_data_classifier),
  };
};

export const transformESv2DataSettingValueToFieldValue = (
  dataSetting: OrgDataSettingsConfig,
): FieldValue => {
  return {
    datatype: DATATYPE_MAP[dataSetting.data_type],
    field: `perspective_entity.${dataSetting.id}.id`,
    type: 'FIELD',
    model: deriveModel(dataSetting.unit21_data_classifier),
  };
};

const DATATYPE_MAP: Record<DataSettingFieldType, OperandType> = {
  ANY: 'text',
  TEXT: 'text',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATE_TIME: 'datetime',
  ENUM: 'enum',
  LIST: 'list',
  ENTITY_REFERENCE: 'number',
  INSTRUMENT_REFERENCE: 'number',
  JSON: 'text', // not sure what to do here
};

export const determineOperator = (
  condition: CompositeQueryTreeNode,
): DmOperator => {
  // NOTE: condition's operator should never be BETWEEN
  if (!(condition.operator in ConditionalOperators)) return condition.operator;

  const leftOperand = condition.operands[0];
  const rightOperand = condition.operands[1];
  let operatorForBE = condition.operator;

  if (leftOperand) {
    const leftOperandIsFieldType = leftOperand.type === 'FIELD';
    const rightOperandIsFieldType =
      rightOperand && rightOperand.type === 'FIELD';
    const isNestedStandardField =
      leftOperandIsFieldType &&
      !leftOperand.field.includes('custom_data') &&
      leftOperand.field.includes('.');
    const isNotTxnEvent =
      leftOperandIsFieldType && leftOperand.model !== 'txn_event';
    const isSenderOrReceiver =
      leftOperandIsFieldType &&
      ['sender', 'receiver'].some((value) => leftOperand.field.includes(value));
    const isNotUserOrBusiness =
      leftOperandIsFieldType &&
      !['user', 'business'].some((value) => leftOperand.field.includes(value));
    const isManyCardinality =
      leftOperandIsFieldType &&
      isNotUserOrBusiness &&
      (isSenderOrReceiver || isNotTxnEvent) &&
      isNestedStandardField;

    if (leftOperandIsFieldType) {
      switch (condition.operator) {
        case ConditionalOperators.EQ: // one to one
        case ConditionalOperators.ANY_EQ:
          if (rightOperandIsFieldType) {
            if (
              leftOperand.cardinality === 'many' &&
              rightOperand.cardinality === 'many'
            )
              operatorForBE = ConditionalOperators.ANY_IN;
            else if (
              leftOperand.cardinality === 'one' &&
              rightOperand.cardinality === 'one'
            )
              operatorForBE = ConditionalOperators.EQ;
            else operatorForBE = ConditionalOperators.ANY_EQ;
          } else if (leftOperand.cardinality === 'many') {
            // rightOperand is not a single FieldValue;
            if (rightOperandIsFieldType && isArray(rightOperand))
              operatorForBE = ConditionalOperators.ANY_IN;
            else operatorForBE = ConditionalOperators.ANY_EQ;
          } else if (rightOperand && rightOperand.type === 'CONSTANT') {
            operatorForBE = isArray(rightOperand)
              ? ConditionalOperators.IN
              : ConditionalOperators.EQ;
          }
          break;
        case ConditionalOperators.NEQ:
        case ConditionalOperators.NONE_EQ:
          if (rightOperandIsFieldType) {
            operatorForBE =
              leftOperand.datatype === rightOperand.datatype
                ? ConditionalOperators.NEQ
                : ConditionalOperators.NONE_IN;
          } else if (
            rightOperand &&
            rightOperand.type === 'CONSTANT' &&
            isArray(rightOperand.value)
          ) {
            operatorForBE = ConditionalOperators.NONE_IN;
          } else if (
            leftOperand.field.includes('custom_data') &&
            leftOperand.field.includes('.')
          ) {
            operatorForBE = ConditionalOperators.NONE_EQ;
          } else operatorForBE = ConditionalOperators.NEQ;
          break;
        case ConditionalOperators.LIKE:
        case ConditionalOperators.ANY_LIKE:
          operatorForBE = isManyCardinality
            ? ConditionalOperators.ANY_LIKE
            : ConditionalOperators.LIKE;
          break;
        case ConditionalOperators.NOT_LIKE:
        case ConditionalOperators.NONE_LIKE:
          operatorForBE = isManyCardinality
            ? ConditionalOperators.NONE_LIKE
            : ConditionalOperators.NOT_LIKE;
          break;
        case ConditionalOperators.IN:
        case ConditionalOperators.ANY_IN:
          operatorForBE = isManyCardinality
            ? ConditionalOperators.ANY_IN
            : ConditionalOperators.IN;
          break;
        case ConditionalOperators.NOT_IN:
        case ConditionalOperators.NONE_IN:
          operatorForBE = isManyCardinality
            ? ConditionalOperators.NONE_IN
            : ConditionalOperators.NOT_IN;
          break;
        case ConditionalOperators.IS_EMPTY:
          operatorForBE = isManyCardinality
            ? ConditionalOperators.ANY_IS_EMPTY
            : ConditionalOperators.IS_EMPTY;
          break;
        case ConditionalOperators.IS_NOT_EMPTY:
          operatorForBE = isManyCardinality
            ? ConditionalOperators.NONE_IS_EMPTY
            : ConditionalOperators.IS_NOT_EMPTY;
          break;
        default:
      }
    }
  }
  return operatorForBE as ConditionalOperators;
};

export const EMPTY_OPERATORS = () =>
  new Set<string>([
    ConditionalOperators.IS_EMPTY,
    ConditionalOperators.IS_NOT_EMPTY,
    ConditionalOperators.ANY_IS_EMPTY,
    ConditionalOperators.NONE_IS_EMPTY,
  ]);

export const transformConditionOperatorsForBE = (
  condition: CompositeQueryTreeNode,
): CompositeQueryTreeNode => {
  if (!condition.operands.length) return condition;
  const conditionCopy = cloneDeep(condition);
  condition.operands.forEach((operand, index) => {
    if (
      operand?.type === 'rule_condition_composite' &&
      operand.operator !== ConditionalOperators.BETWEEN &&
      conditionCopy.operands[index] !== null
    ) {
      const operandCopy = conditionCopy.operands[index];
      const operatorForBE = determineOperator(operand);
      if (operandCopy && operandCopy.type === 'rule_condition_composite')
        operandCopy.operator = operatorForBE;
      transformConditionOperatorsForBE(operand);
    }
  });
  return conditionCopy;
};
// NOTE: end Dropdown builder helper functions

export const isLegacySequenceRule = (scenarioName: string) => {
  return LEGACY_SEQUENCE_RULES.some((currName) => scenarioName === currName);
};
export const isAchRiskScoreEnabledRule = (scenarioName: string) => {
  return ACH_RISK_SCORE_ENABLED_SCENARIO_RULES.some(
    (currName) => scenarioName === currName,
  );
};

export const isConsortiumRule = (scenarioName: string) => {
  return CONSORTIUM_RULES.includes(scenarioName);
};

export const isWatchlistRule = (scenarioName: string) => {
  return scenarioName === WATCHLIST_RULE_NAME;
};

export const isCheckFraudRuleDSN = (scenarioName: string): boolean => {
  return scenarioName === SCENARIO_DUPLICATE_SERIAL_NUMBER_NAME;
};

export const isCheckFraudRuleMSN = (scenarioName: string): boolean => {
  return scenarioName === SCENARIO_MISSING_SERIAL_NUMBER_NAME;
};

export const isSequenceRollingWindowRule = (scenarioName: string): boolean => {
  return scenarioName === SCENARIO_SEQUENCE_ROLLING_WINDOW_NAME;
};

export const isSequenceRollingWindowOneStepRule = (
  scenarioName: string,
): boolean => {
  return scenarioName === SCENARIO_SEQUENCE_ROLLING_WINDOW_ONE_STEP_NAME;
};

export const isSequenceCumulativeAggregateRule = (
  scenarioName: string,
): boolean => {
  return scenarioName === SCENARIO_SEQUENCE_CUMULATIVE_AGGREGATE_NAME;
};

export const isSequenceCumulativeAggregateUSPSRule = (
  scenarioName: string,
): boolean => {
  return scenarioName === SCENARIO_SEQUENCE_CUMULATIVE_AGGREGATE_USPS_NAME;
};

export const isSequenceDailyAggregateRule = (scenarioName: string): boolean => {
  return scenarioName === SCENARIO_SEQUENCE_DAILY_AGGREGATE_NAME;
};

export const isCheckFraudRule = (scenarioName: string): boolean => {
  return CHECK_FRAUD_RULES_ALL.includes(scenarioName);
};

export const isScenarioBuilderCheckFraudRule = (
  scenarioName: string,
): boolean => {
  return SCENARIO_BUILDER_CHECK_FRAUD_RULES.includes(scenarioName);
};

export const isAchMultipleFailedValidationAttemptsRule = (
  scenarioName: string,
): boolean => {
  return scenarioName === SCENARIO_MULTIPLE_FAILED_VALIDATION_ATTEMPTS_NAME;
};
export const isAchRiskScoreRule = (scenarioName: string): boolean => {
  return scenarioName === SCENARIO_ACH_RISK_SCORE_NAME;
};

export const isAchConsortiumRule = (scenarioName: string): boolean => {
  return scenarioName === SCENARIO_ACH_CONSORTIUM_NAME;
};
export const isAchMismatchedRule = (scenarioName: string): boolean => {
  return scenarioName === SCENARIO_ACH_MISMATCHED_NAME;
};
export const isAchDuplicatedRule = (scenarioName: string): boolean => {
  return scenarioName === SCENARIO_ACH_DUPLICATE_NAME;
};

export const getTimeWindowOption = (scenarioName: string | undefined) => {
  const timeWindowSmallerThanTwoMonths = TIME_WINDOW_OPTIONS.slice(
    7,
    19,
  ).filter((timeWindow) => !timeWindow.key.endsWith('month'));

  return scenarioName && isLegacySequenceRule(scenarioName)
    ? timeWindowSmallerThanTwoMonths
    : TIME_WINDOW_OPTIONS;
};

export const isDmBackEndDateFormat = (string: string): boolean => {
  return /^([1-2]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])\s(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/.test(
    string,
  );
};

const isCondition = (node: QueryTreeNode): node is CompositeQueryTreeNode => {
  if (!node) {
    return false;
  }
  return node.type === 'COMPOSITE' || node.type === 'rule_condition_composite';
};

export const countConditions = (
  ruleCondition: QueryTreeNode,
  isPapaNode: boolean,
) => {
  let count = 0;

  if (!isCondition(ruleCondition)) {
    return count;
  }

  if (!isPapaNode) {
    count += 1;
  }

  if (ruleCondition.operands.length > 0) {
    count += ruleCondition.operands.reduce(
      (acc, curr) => acc + countConditions(curr, false),
      0,
    );
  }

  return count;
};

const addNestedGeolocationFieldsForDropdownBuilder = (
  symbolTableToUpdate: SymbolTable,
  prefix: Prefix,
) => {
  /* Mutates passed `symbolTableToUpdate` param, adds more keys to the table. */
  const symbolTable = symbolTableToUpdate;
  const geolocationPath = `${prefix.key}.geolocation`;
  const geolocationPathText = `${prefix.label} / Geolocation`;
  symbolTable[`${geolocationPath}.country`] = {
    type: 'text',
    text: `${geolocationPathText} / Country`,
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: `${geolocationPath}.country`,
        label: `${geolocationPath}.country`,
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  };
  symbolTable[`${geolocationPath}.city`] = {
    type: 'text',
    text: `${geolocationPathText} / City`,
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: `${geolocationPath}.city`,
        label: `${geolocationPath}.city`,
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  };
  symbolTable[`${geolocationPath}.postal_code`] = {
    type: 'text',
    text: `${geolocationPathText} / Postal code`,
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: `${geolocationPath}.postal_code`,
        label: `${geolocationPath}.postal_code`,
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  };
  symbolTable[`${geolocationPath}.longitude`] = {
    type: 'text',
    text: `${geolocationPathText} / Longitude`,
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: `${geolocationPath}.longitude`,
        label: `${geolocationPath}.longitude`,
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  };
  symbolTable[`${geolocationPath}.latitude`] = {
    type: 'text',
    text: `${geolocationPathText} / Latitude`,
    value: {
      operandType: 'text',
      unit21DataClassifier: Unit21DataClassifier.EVENT,
      value: {
        type: 'FIELD',
        field: `${geolocationPath}.latitude`,
        label: `${geolocationPath}.latitude`,
        model: 'txn_event',
        datatype: 'string',
        cardinality: 'many',
      },
    },
  };
};

export const addLatestRiskFieldsToEventForDropdownBuilder = (
  symbolTableToUpdate: SymbolTable,
  prefix: Prefix | null,
  unit21DataClassifier: Unit21DataClassifier,
  isRiskRatingsEnabled: boolean,
  hasActiveRiskRatingModel: boolean,
) => {
  /* Mutates passed `symbolTableToUpdate` param, adds more keys to the table. */
  const symbolTable = symbolTableToUpdate;

  const model = deriveModel(unit21DataClassifier);

  const riskFields = [
    {
      key: U21_LATEST_RISK_SCORE_KEY,
      dataType: 'number',
      text: 'Unit21 Entity risk score',
      operandType: 'number',
      dropdownValues: null,
    },
    {
      key: U21_RISK_LEVEL_KEY,
      dataType: 'text',
      text: 'Unit21 Entity risk level',
      operandType: 'multiselect',
      dropdownValues: U21_RISK_LEVELS.map((riskLevel) => ({
        text: riskLevel,
        value: riskLevel.toLowerCase(),
      })),
    },
  ] as const;

  riskFields.forEach(({ key, dataType, text, operandType, dropdownValues }) => {
    const fieldPath = prefix ? `${prefix.key}.${key}` : key;
    const fieldText = prefix ? `${prefix.label} / ${text}` : text;
    symbolTable[fieldPath] = {
      type: dataType,
      text: fieldText,
      value: {
        operandType,
        dropdownValues,
        unit21DataClassifier,
        value: {
          type: 'FIELD',
          field: fieldPath,
          label: fieldPath,
          model,
          datatype: dataType,
        },
      },
      disabled: !isRiskRatingsEnabled || !hasActiveRiskRatingModel,
      tooltip: hasActiveRiskRatingModel ? null : RISK_RATINGS_TOOLTIP,
    };
  });
};

export const addLatestRiskFieldsToEventForAdvancedBuilder = (
  symbolTableToUpdate: SymbolTable,
  prefix: Prefix | null,
  unit21DataClassifier: Unit21DataClassifier,
) => {
  /* Mutates passed `symbolTableToUpdate` param, adds more keys to the table. */
  const symbolTable = symbolTableToUpdate;

  const model = deriveModel(unit21DataClassifier);

  const riskFields = [
    {
      key: U21_LATEST_RISK_SCORE_KEY,
      dataType: 'number',
      text: 'Unit21 Entity risk score',
    },
    {
      key: U21_RISK_LEVEL_KEY,
      dataType: 'text',
      text: 'Unit21 Entity risk level',
    },
  ] as const;

  riskFields.forEach(({ key, dataType, text }) => {
    const fieldPath = prefix ? `${prefix.key}.${key}` : key;
    const label = prefix ? `${toSentenceCase(prefix.label)} / ${text}` : text;
    symbolTable[fieldPath] = {
      type: 'FIELD',
      field: fieldPath,
      label,
      model,
      datatype: dataType,
    };
  });
};

export const getRtrDecision = (decision: string | undefined): string => {
  return decision && DECISION_MAP[decision]
    ? DECISION_MAP[decision]
    : DECISION_MAP.deny;
};

const addNestedGeolocationFieldsForAdvancedBuilder = (
  symbolTableToUpdate: SymbolTable,
  prefix: Prefix,
) => {
  /* Mutates passed `symbolTableToUpdate` param, adds more keys to the table. */
  const symbolTable = symbolTableToUpdate;
  const geolocationPath = `${prefix.key}.geolocation`;
  const geolocationPathText = `${prefix.label} / Geolocation`;
  symbolTable[`${geolocationPath}.country`] = {
    type: 'FIELD',
    field: `${geolocationPath}.country`,
    label: `${geolocationPathText} / Country`,
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  };
  symbolTable[`${geolocationPath}.city`] = {
    type: 'FIELD',
    field: `${geolocationPath}.city`,
    label: `${geolocationPathText} / City`,
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  };
  symbolTable[`${geolocationPath}.postal_code`] = {
    type: 'FIELD',
    field: `${geolocationPath}.postal_code`,
    label: `${geolocationPathText} / Postal code`,
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  };
  symbolTable[`${geolocationPath}.longitude`] = {
    type: 'FIELD',
    field: `${geolocationPath}.longitude`,
    label: `${geolocationPathText} / Longitude`,
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  };
  symbolTable[`${geolocationPath}.latitude`] = {
    type: 'FIELD',
    field: `${geolocationPath}.latitude`,
    label: `${geolocationPathText} / Latitude`,
    model: 'txn_event',
    datatype: 'string',
    cardinality: 'many',
  };
};
